import React, { useEffect, useState } from "react";
import BackButtons from "../BackButton";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import { FaSave } from "react-icons/fa";
import CreateBank from "../Bank/CreateBank";
import { MdAdd } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { TbEdit } from "react-icons/tb";
import EditBank from "../Bank/EditBank";
import { Table } from "../Table";

const initialState = {
  principalName: "",
  company: "",
  contactNo: "",
  contactName: "",
  address: "",
  bankInfo: [],
};

const CreateBusiness = () => {
  const [formData, setFormData] = useState(initialState);
  const [showModal, setShowModal] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [bankInfo, setBankInfo] = useState([]);
  const [index, setIndex] = useState(0);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getData("principals", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    bankInfo.forEach((bank) => {
      bank.principal = formData.principalName;
    });

    formData.bankInfo = bankInfo;

    postData("principals/create-principal", { principal: formData });
    setFormData(initialState);
    setBankInfo([]);

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  const handleRemoveBank = (index) => {
    const newBankInfo = [...bankInfo];
    newBankInfo.splice(index, 1);
    setBankInfo(newBankInfo);
  };

  useEffect(() => {
    setShowModal(false);
    setUpdateModal(false);
  }, [bankInfo]);

  return (
    <section>
      <BackButtons />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create Principal
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Principal Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter PrincipalName"
                name="principalName"
                className={`${styles.input}`}
                value={formData?.principalName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    principalName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Company Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter CompanyName"
                name="company"
                className={`${styles.input}`}
                value={formData?.company || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    company: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Contact Number
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter ContactNumber"
                name="contactNo"
                className={`${styles.input}`}
                value={formData?.contactNo || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contactNo: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Contact Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter ContactName"
                name="contactName"
                className={`${styles.input}`}
                value={formData?.contactName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contactName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Address
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter Address"
                name="address"
                className={`${styles.input}`}
                value={formData?.address || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full"></div>
          </div>

          {/* add banks */}
          <div className="flex flex-col items-start gap-2.5">
            <span
              className={`${styles.button} !bg-[#0d9e47]`}
              onClick={() => setShowModal(!showModal)}
            >
              <MdAdd /> Add Bank
            </span>

            <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-2.5">
              {bankInfo.length > 0 &&
                bankInfo.map((bank, index) => (
                  <div
                    key={index}
                    className={`shadow-md p-2 flex flex-col items-center gap-2 w-full rounded-md text-xs font-semibold`}
                  >
                    <div className="flex justify-between items-center w-full">
                      <span
                        className="cursor-pointer text-sm text-red-600"
                        onClick={() => handleRemoveBank(index)}
                      >
                        <RxCross2 />
                      </span>
                      <span
                        className="cursor-pointer text-sm text-blue-500"
                        onClick={() => {
                          setUpdateModal(!updateModal);
                          setIndex(index);
                        }}
                      >
                        <TbEdit />
                      </span>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p>{bank.accountNo}</p>
                      <p>{bank.accountName}</p>
                    </div>
                    <div className="flex justify-between items-center w-full">
                      <p>{bank.bankName}</p>
                      <p>{bank.branchName}</p>
                    </div>
                    {/* <div className="flex justify-between items-center w-full">
                      <p>{bank.address}</p>
                      <p>{bank.swiftCode}</p>
                    </div> */}
                    <div className="flex justify-between items-center w-full">
                      <p>{bank.routingNumber}</p>
                      <p>{bank.district}</p>
                    </div>
                  </div>
                ))}
            </div>

            {showModal && (
              <div className="modal modal-open absolute rounded-md">
                <div className="modal-box w-11/12 max-w-full bg-[#eff3f4]">
                  <button
                    onClick={() => setShowModal(!showModal)}
                    className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5"
                  >
                    ✕
                  </button>
                  <h3 className="font-bold text-lg">Add New Bank</h3>
                  <CreateBank bankInfo={bankInfo} setBankInfo={setBankInfo} />
                </div>
              </div>
            )}

            {/* update modal */}
            {updateModal && (
              <div className="modal modal-open absolute rounded-md">
                <div className="modal-box w-11/12 max-w-full bg-[#eff3f4]">
                  <button
                    onClick={() => setUpdateModal(!updateModal)}
                    className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5"
                  >
                    ✕
                  </button>
                  <h3 className="font-bold text-lg">Update Bank Info</h3>
                  <EditBank
                    bankInfo={bankInfo}
                    index={index}
                    setBankInfo={setBankInfo}
                  />
                </div>
              </div>
            )}
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Principal
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <BusinessList data={data} />
      </div>
    </section>
  );
};

export default CreateBusiness;

const BusinessList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "PrincipalCode",
      accessor: "code",
    },
    {
      Header: "PrincipalName",
      accessor: "principalName",
    },
    {
      Header: "CompanyName",
      accessor: "company",
    },
    {
      Header: "ContactNo",
      accessor: "contactNo",
    },
    {
      Header: "ContactName",
      accessor: "contactName",
    },
    {
      Header: "Bank Name",
      accessor: "bankInfo[0].bankName",
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
