/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import Print from "../../components/Print";
import FilterOrderList from "../../components/OrderLists/FilterOrderList";
import { Table } from "../../components/Table";
import { deleteDataByCode, getData } from "../../api/Fetch";
import { format, parse } from "date-fns";
import { BiShow } from "react-icons/bi";
import { RiDeleteBin7Line } from "react-icons/ri";

const OrderListsPage = () => {
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [finalOrders, setFinalOrders] = useState([]);
  const [reload, setReload] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);

  const handleView = (code) => {
    setShowModal(!showModal);
    const temps = orders.filter((order) => order.code === code);
    setItems(temps[0].items);
  };

  const handleDelete = (code) => {
    deleteDataByCode("orders", code);
    setReload(!reload);
  };

  useEffect(() => {
    getData("orders", setOrders);
  }, [reload]);

  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Order ID",
      accessor: "code",
    },
    {
      Header: "Order Date",
      accessor: "createdAt",
      Cell: (cell) => {
        const date = new Date(cell.value);
        return format(date, "dd/MM/yyyy hh:mm a");
      },
    },
    {
      Header: "Principal",
      accessor: "businessName",
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "Order Amount",
      accessor: "totalPrice",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "#0d9e47" }}
              onClick={() => {
                handleView(cell.row.original.code);
              }}
            >
              <BiShow />
            </button>
            <button
              className={`text-lg`}
              style={{ color: "#f44336" }}
              onClick={() => {
                handleDelete(cell.row.original.code);
              }}
            >
              <RiDeleteBin7Line />
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    setFinalOrders([]);
    data.primary
      ? setFinalOrders(
          orders.filter((order) => order.businessName === data.primary)
        )
      : setFinalOrders(orders);
  }, [orders, data, setData]);

  return (
    <section>
      <Print />

      <FilterOrderList data={data} setData={setData} />

      {<Table Columns={Columns} Data={finalOrders} />}

      {showModal && (
        <div className="modal modal-open absolute rounded-md">
          <div className="modal-box w-11/12 max-w-full bg-[#eff3f4]">
            <button
              onClick={() => setShowModal(!showModal)}
              className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5"
            >
              ✕
            </button>
            <h3 className="font-bold text-lg">Ordered Products</h3>
            <Table Columns={ProductColumns} Data={items} />
          </div>
        </div>
      )}
    </section>
  );
};

export default OrderListsPage;

const ProductColumns = [
  {
    Header: "#",
    Cell: ({ cell }) => {
      return cell.row.index + 1;
    },
  },
  {
    Header: "PrincipalName",
    accessor: "businessName",
  },
  {
    Header: "Brand",
    accessor: "brand",
  },
  {
    Header: "Category",
    accessor: "category",
  },
  {
    Header: "Product Code",
    accessor: "code",
  },
  {
    Header: "Product Name",
    accessor: "productName",
  },
  {
    Header: "DP",
    accessor: "dp",
  },
  {
    Header: "TP",
    accessor: "tp",
  },
  {
    Header: "MRP",
    accessor: "mrp",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
];
