import React, { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Buttons from "../../components/Buttons";
import importFormat from "../../assets/products.csv";
import { getData } from "../../api/Fetch";

const ProductWisePriceHistoryPage = () => {
  const [data, setData] = useState([]);

  const importPath = "product-prices-history/import-product-prices-history";
  const exportPath = "product-prices-history/export-product-prices-history";
  const importFormatPath = importFormat;

  useEffect(() => {
    getData("product-prices-history", setData);
  }, []);

  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "ProductCode",
      accessor: "productCode",
    },
    {
      Header: "ProductName",
      accessor: "productName",
    },
    {
      Header: "DP",
      accessor: "dp",
    },
    {
      Header: "TP",
      accessor: "tp",
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Updated At",
      accessor: "date",
    },
  ];

  return (
    <section>
      <Buttons
        importPath={importPath}
        exportPath={exportPath}
        importFormatPath={importFormatPath}
      />
      <Table Columns={Columns} Data={data} />
    </section>
  );
};

export default ProductWisePriceHistoryPage;
