import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";
import { getData, postData } from "../../api/Fetch";
import { Table } from "../Table";

const initialState = {
  channelType: "",
  channelDescription: "",
};

const CreateChannelType = () => {
  const [formData, setFormData] = useState(initialState);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getData("channel-types", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    postData("channel-types/create-channel-type", {
      channelType: formData,
    });
    setFormData(initialState);

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create Channel Type
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="w-full">
            <label className="text-sm font-medium mb-1">
              Name<span className="text-red-500">*</span>
            </label>
            <input
              type="text"
              required
              placeholder="Enter ChannelType"
              name="channelType"
              className={`${styles.input}`}
              value={formData?.channelType || ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  channelType: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-full">
            <label className="text-sm font-medium mb-1">
              Description<span className="text-red-500">*</span>
            </label>
            <textarea
              required
              placeholder="Enter ChannelDescription"
              name="channelDescription"
              className={`${styles.textarea}`}
              value={formData?.channelDescription || ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  channelDescription: e.target.value,
                });
              }}
            />
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Channel Type
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <ChannelTypeList data={data} />
      </div>
    </section>
  );
};

export default CreateChannelType;

const ChannelTypeList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Channel Type Code",
      accessor: "code",
    },
    {
      Header: "Channel Type",
      accessor: "channelType",
    },
    {
      Header: "Channel Type Description",
      accessor: "channelDescription",
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
