import axios from "axios";
import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

export const postData = async (route, data) => {
  try {
    const response = await axios.post(`${BASE_URL}/${route}`, data);

    toast.success(response.data.message);
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`);
  }
};

export const getData = async (route, setData) => {
  try {
    const response = await axios.get(`${BASE_URL}/${route}`);

    setData(response.data.data);
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`);
  }
};

export const getDataByCode = async (route, code, setData) => {
  try {
    const response = await axios.get(`${BASE_URL}/${route}/${code}`);

    setData(response.data.data);
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`);
  }
};

export const updateDataByCode = async (route, code, data) => {
  try {
    const response = await axios.put(`${BASE_URL}/${route}/${code}`, data);

    toast.success(response.data.message);
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`);
  }
};

export const updateByCode = async (route, code) => {
  try {
    const response = await axios.put(`${BASE_URL}/${route}/${code}`);

    toast.success(response.data.message);
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`);
  }
};

export const deleteDataByCode = async (route, code) => {
  try {
    const response = await axios.delete(`${BASE_URL}/${route}/${code}`);

    toast.success(response.data.message);
  } catch (error) {
    toast.error(`${error?.response?.data?.message}`);
  }
};
