import React, { useState } from "react";
import {
  AdvancedMarker,
  InfoWindow,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { MdPushPin } from "react-icons/md";

const AdvancedRetailerMarker = ({ item }) => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(false);
  const [markerRef, marker] = useAdvancedMarkerRef();

  return (
    <>
      <AdvancedMarker
        ref={markerRef}
        onClick={() => setInfoWindowOpen(!infoWindowOpen)}
        position={{
          lat: parseFloat(item.location.lat),
          lng: parseFloat(item.location.long),
        }}
        title={"Retailer"}
      >
        <MdPushPin size={45} color="blue" />
      </AdvancedMarker>

      {infoWindowOpen && (
        <InfoWindow
          anchor={marker}
          position={{
            lat: parseFloat(item.location.lat),
            lng: parseFloat(item.location.long),
          }}
          onCloseClick={() => setInfoWindowOpen(false)}
        >
          <div className="flex flex-col gap-0.5">
            <img src={item?.avatar} alt="retailer avatar" />
            <small>Name: {item?.retailerName}</small>
            <small>Address: {item?.addressInEnglish}</small>
            <small>Mobile: {item?.mobile}</small>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default AdvancedRetailerMarker;
