import { APIProvider, Map } from "@vis.gl/react-google-maps";
import ClusteredMarkers from "./ClusteredMarkers";

const Maps = ({ zoom, setZoom, setBounds, orders, data }) => {
  const position = {
    lat: data[0]?.location.lat || 23.81093,
    lng: data[0]?.location.long || 90.36542,
  };

  return (
    <APIProvider apiKey={`${process.env.REACT_APP_MAP_API_KEY}`}>
      <div
        className={`mt-1 w-full h-[calc(100vh-116px)] rounded-lg overflow-hidden border-[4px] border-[#619494]`}
      >
        <Map
          defaultCenter={position}
          zoom={zoom}
          mapId={`${process.env.REACT_APP_MAP_ID}`}
          onZoomChanged={(e) => setZoom(e.detail.zoom)}
          gestureHandling={"greedy"}
          onBoundsChanged={(e) => setBounds(e.detail.bounds)}
        >
          <ClusteredMarkers orders={orders} data={data} />
        </Map>
      </div>
    </APIProvider>
  );
};

export default Maps;
