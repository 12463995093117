import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import BackButton from "../BackButton";
import { getDataByCode, updateDataByCode } from "../../api/Fetch";
import { GrUpdate } from "react-icons/gr";

const EditSR = () => {
  const [formData, setFormData] = useState({});
  const { code } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateDataByCode("employees", code, { employee: formData });
  };

  useEffect(() => {
    getDataByCode("employees", code, setFormData);
  }, [code]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit Employee
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee ID
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter EmployeeID"
                name="id"
                className={`${styles.input}`}
                value={formData?.id || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    id: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full"></div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter EmployeeName"
                name="srName"
                className={`${styles.input}`}
                value={formData?.srName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    srName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Name Bangla
              </label>
              <input
                type="text"
                placeholder="Enter EmployeeNameBangla"
                name="srNameBangla"
                className={`${styles.input}`}
                value={formData?.srNameBangla || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    srNameBangla: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Gender
                <span className="text-red-500">*</span>
              </label>
              <select
                placeholder="Select Gender"
                name="gender"
                className={`${styles.input}`}
                value={formData?.gender || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    gender: e.target.value,
                  });
                }}
              >
                <option value="">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Female</option>
              </select>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Date of Birth
                <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                required
                placeholder="Enter DateOfBirth"
                name="dateOfBirth"
                className={`${styles.input}`}
                value={formData?.dateOfBirth || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    dateOfBirth: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Level
                <span className="text-red-500">*</span>
              </label>
              <select
                required
                name="level"
                placeholder="Select Level"
                className={`${styles.input}`}
                value={formData?.level || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    level: e.target.value,
                  });
                }}
              >
                <option value="">Select Level</option>
                <option value="Level4">Level4</option>
                <option value="Level3">Level3</option>
                <option value="Level2">Level2</option>
                <option value="Level1">Level1</option>
              </select>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Mobile<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter MobileNumber"
                name="mobile"
                className={`${styles.input}`}
                value={formData?.mobile || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mobile: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Joining Date
                <span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                required
                placeholder="Enter JoiningDate"
                name="joiningDate"
                className={`${styles.input}`}
                value={formData?.joiningDate || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    joiningDate: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee NID
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter EmployeeNID"
                name="nid"
                className={`${styles.input}`}
                value={formData?.nid || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    nid: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Reporting Level
              </label>
              <select
                name="reportingLevel"
                placeholder="Select ReportingLevel"
                className={`${styles.input}`}
                value={formData?.reportingLevel || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    reportingLevel: e.target.value,
                  });
                }}
              >
                <option value="">Select Level</option>
                <option value="Level4">Level4</option>
                <option value="Level3">Level3</option>
                <option value="Level2">Level2</option>
                <option value="Level1">Level1</option>
              </select>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Status<span className="text-red-500">*</span>
              </label>
              <select
                placeholder="Status"
                name="status"
                className={`${styles.input}`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Save Employee
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditSR;
