/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { getData } from "../../api/Fetch";
import axios from "axios";
import MapsWithoutFilter from "../Maps/MapsWithoutFilter";

const MapsWithoutFilterPage = () => {
  const [retailers, setRetailers] = useState([]);
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getData("retailers/map", setRetailers);
    handleOrders();
    setLoading(false);
  }, [setLoading]);

  const handleOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/fortyfivedegree/get_orders_for_map`
      );
      setOrders(response.data.data);
    } catch (error) {}
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div>
      <MapsWithoutFilter data={retailers} orders={orders} />
    </div>
  );
};

export default MapsWithoutFilterPage;
