import React from "react";
import styles from "../styles/styles";
import { BsPrinter } from "react-icons/bs";
import toast from "react-hot-toast";

const Print = () => {
    const handlePrint = () => {
        toast.success("Coming soon...");
    };

    return (
        <section>
            <div className="flex items-center py-2 lg:px-3">
                <div className="flex-1 flex justify-end gap-2">
                    <button onClick={handlePrint} className={`${styles.button}`}>
                        <BsPrinter className="mt-0.5" /> Print
                    </button>
                </div>
            </div>
            <hr className="border-gray-300 mt-1" />
        </section>
    );
};

export default Print;
