import React, { useEffect, useState } from "react";
import UserRequestButton from "../../components/UserRequest/UserRequestButton";
import { Table } from "../../components/Table";
import { color } from "../../static/data";
import { deleteDataByCode, getData, updateByCode } from "../../api/Fetch";
import styles from "../../styles/styles";

const UserRequestPage = () => {
  const [data, setData] = useState([]);
  const [toggle, setToggle] = useState(false);

  useEffect(() => {
    getData("auth/requests", setData);
  }, [toggle]);

  const handleAccept = (code) => {
    updateByCode("auth/accept", code);
    setToggle(!toggle);
  };

  const handleReject = (code) => {
    updateByCode("auth/reject", code);
    setToggle(!toggle);
  };

  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Response",
      Cell: ({ cell }) => {
        return (
          <>
            {cell.row.original.status === "pending" ? (
              <div className="flex gap-2 items-center">
                <button
                  onClick={() => handleAccept(cell.row.original.code)}
                  className={`${styles.button} h-7 bg-green-500 hover:bg-green-700`}
                >
                  Accept
                </button>
                <button
                  onClick={() => handleReject(cell.row.original.code)}
                  className={`${styles.button} h-7 bg-red-500 hover:bg-red-700`}
                >
                  Reject
                </button>
              </div>
            ) : (
              <p className="font-semibold">{cell.row.original.status}</p>
            )}
          </>
        );
      },
    },
  ];

  const handleDelete = (code) => {
    deleteDataByCode("auth/delete", code);
    setToggle(!toggle);
  };

  const handles = [
    {
      name: "Delete",
      handle: handleDelete,
      color: color.delete,
    },
  ];

  return (
    <section>
      <UserRequestButton />

      <Table Columns={Columns} Data={data} handles={handles} />
    </section>
  );
};

export default UserRequestPage;
