import React, { useState } from "react";
import toast from "react-hot-toast";
import { logo } from "../../static/images";
import styles from "../../styles/styles";
import { useNavigate } from "react-router-dom";
import { FiEye, FiEyeOff } from "react-icons/fi";

const LoginPage = () => {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState(false);

    const handleSubmit = (e) => {
        e.preventDefault();

        const userId = e.target.userId.value;
        const password = e.target.password.value;

        if (!userId || !password) {
            toast.error("Fill up required fields");
        }

        if (userId !== "admin" || password !== "admin") {
            toast.error("Invalid credentials");
        }

        if (userId === "admin" && password === "admin") {
            toast.success("Login Successful!");
            sessionStorage.setItem("token", "true");
            navigate("/dashboard");
        }
    };

    return (
        <section className="min-h-screen w-full sm:w-[80%] md:w-[70%] lg:w-[60%] xl:w-[50%] 2xl:w-[40%] mx-auto flex justify-center items-center">
            <div className="bg-[#fff] w-full p-10 rounded-md flex gap-12 items-center">
                <img
                    className="hidden md:block w-52 lg:w-60"
                    src={logo}
                    alt="logo 45"
                />
                <div className="w-full">
                    <h3 className="text-2xl text-blue-500">
                        <span className="font-bold text-[#031a61]">
                            Secondary
                        </span>{" "}
                        Sales!
                    </h3>
                    <p className="text-sm font-medium text-gray-600 mt-1">
                        Welcome Bake! Login to your account.
                    </p>

                    <form
                        onSubmit={handleSubmit}
                        className="mt-4 lg:mt-8 space-y-2"
                    >
                        <div className="flex flex-col">
                            <label
                                htmlFor="userId"
                                className="text-sm font-semibold text-gray-700"
                            >
                                User ID<span className="text-red-500">*</span>
                            </label>
                            <input
                                className={`${styles.input}`}
                                type="text"
                                required
                                name="userId"
                                id="userId"
                                placeholder="UserID"
                            />
                        </div>
                        <div className="flex flex-col">
                            <label
                                htmlFor="password"
                                className="text-sm font-semibold text-gray-700"
                            >
                                Password<span className="text-red-500">*</span>
                            </label>
                            <div className="relative">
                                <input
                                    className={`${styles.input}`}
                                    type={`${
                                        showPassword ? "text" : "password"
                                    }`}
                                    required
                                    name="password"
                                    id="password"
                                    placeholder="Password"
                                />
                                {showPassword ? (
                                    <FiEyeOff
                                        size={18}
                                        className="absolute top-2 right-3 cursor-pointer text-gray-500"
                                        onClick={() => setShowPassword(false)}
                                    />
                                ) : (
                                    <FiEye
                                        size={18}
                                        className="absolute top-2 right-3 cursor-pointer text-gray-500"
                                        onClick={() => setShowPassword(true)}
                                    />
                                )}
                            </div>
                        </div>
                        <button
                            type="submit"
                            className={`${styles.button} h-10 w-40 !mt-4`}
                        >
                            Login
                        </button>
                    </form>
                </div>
            </div>
        </section>
    );
};

export default LoginPage;
