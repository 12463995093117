import { APIProvider, Map } from "@vis.gl/react-google-maps";
import AdvancedRetailerMarker from "./AdvancedRetailerMarker";
import AdvancedOrderMarker from "./AdvancedOrderMarker";
import { useState } from "react";

const MapsWithoutFilter = ({ data, orders }) => {
  const [zoom, setZoom] = useState(8);
  const position = { lat: 23.81093, lng: 90.36542 };

  return (
    <APIProvider apiKey={`${process.env.REACT_APP_MAP_API_KEY}`}>
      <div
        className={`mt-1 w-full h-[calc(100vh-80px)] rounded-lg overflow-hidden border-[4px] border-[#619494]`}
      >
        <Map
          zoom={zoom}
          defaultCenter={position}
          mapId={`${process.env.REACT_APP_MAP_ID}`}
          onZoomChanged={(e) => setZoom(e.detail.zoom)}
        >
          {data?.map((item, index) => (
            <div key={index}>
              {item && <AdvancedRetailerMarker item={item} />}
            </div>
          ))}

          {orders?.map((item, index) => (
            <AdvancedOrderMarker key={index} item={item} />
          ))}
        </Map>
      </div>
    </APIProvider>
  );
};

export default MapsWithoutFilter;
