import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";

const initialState = {
    retailerName: "",
    retailerNameBangla: "",
    distributorCode: "",
    beatCode: "",
    channelType: "",
    contactPerson: "",
    addressInEnglish: "",
    addressInBangla: "",
    phone: "",
    mobile: "",
    status: "active",
};

const CreateRetailer = () => {
    const [formData, setFormData] = useState(initialState);
    const [distributorList, setDistributorList] = useState([]);
    const [beatList, setBeatList] = useState([]);
    const [channelTypeList, setChannelTypeList] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        postData("retailers/create-retailer", { retailer: formData });
        setFormData(initialState);
    };

    useEffect(() => {
        getData("distributors", setDistributorList);
        getData("beats", setBeatList);
        getData("channel-types", setChannelTypeList);
    }, []);

    return (
        <section>
            <BackButton />

            <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
                <p className="font-semibold uppercase text-[15px] mb-2">
                    Create Retailer
                </p>

                <form onSubmit={handleSubmit} className="space-y-2.5">
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Retailer Name
                                <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                required
                                placeholder="Enter RetailerName"
                                name="retailerName"
                                className={`${styles.input}`}
                                value={formData?.retailerName || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        retailerName: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Retailer Name Bangla
                            </label>
                            <input
                                type="text"
                                placeholder="Enter RetailerNameBangla"
                                name="reatailerNameBangla"
                                className={`${styles.input}`}
                                value={formData?.retailerNameBangla || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        retailerNameBangla: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Distributor Code
                                <span className="text-red-500">*</span>
                            </label>
                            <input
                                list="distributors"
                                type="text"
                                required
                                placeholder="Enter DistributorCode"
                                name="distributorCode"
                                className={`${styles.input}`}
                                value={formData?.distributorCode || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        distributorCode: e.target.value,
                                    });
                                }}
                            />
                            <datalist id="distributors">
                                {distributorList.map((item, index) => (
                                    <option key={index} value={item.code}>
                                        {item.code +
                                            " | " +
                                            item.distributorName}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Beat Code<span className="text-red-500">*</span>
                            </label>
                            <input
                                list="beats"
                                type="text"
                                required
                                placeholder="Enter BeatCode"
                                name="beatCode"
                                className={`${styles.input}`}
                                value={formData?.beatCode || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        beatCode: e.target.value,
                                    });
                                }}
                            />
                            <datalist id="beats">
                                {beatList.map((item, index) => (
                                    <option key={index} value={item.code}>
                                        {item.code + " | " + item.beatName}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                    </div>
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Channel Type
                                <span className="text-red-500">*</span>
                            </label>
                            <input
                                list="channelTypes"
                                type="text"
                                required
                                placeholder="Enter ChannelType"
                                name="channelType"
                                className={`${styles.input}`}
                                value={formData?.channelType || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        channelType: e.target.value,
                                    });
                                }}
                            />
                            <datalist id="channelTypes">
                                {channelTypeList.map((item, index) => (
                                    <option key={index} value={item.channelType}>
                                        {`${item.code} | ${item.channelType}`}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Contact Person
                                <span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                required
                                placeholder="Enter ContactPerson"
                                name="contactPerson"
                                className={`${styles.input}`}
                                value={formData?.contactPerson || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        contactPerson: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Address<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                required
                                placeholder="Enter Address"
                                name="addressInEnglish"
                                className={`${styles.input}`}
                                value={formData?.addressInEnglish || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        addressInEnglish: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Address Bangla
                            </label>
                            <input
                                type="text"
                                placeholder="Enter AddressBangla"
                                name="addressInBangla"
                                className={`${styles.input}`}
                                value={formData?.addressInBangla || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        addressInBangla: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Phone
                            </label>
                            <input
                                type="text"
                                placeholder="Enter Phone"
                                name="phone"
                                className={`${styles.input}`}
                                value={formData?.phone || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        phone: e.target.value,
                                    });
                                }}
                            />
                        </div>
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Mobile<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="text"
                                required
                                placeholder="Enter Mobile"
                                name="mobile"
                                className={`${styles.input}`}
                                value={formData?.mobile || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        mobile: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full flex flex-col">
                            <label className="text-sm font-medium mb-1">
                                Status<span className="text-red-500">*</span>
                            </label>
                            <select
                                placeholder="Status"
                                name="status"
                                className={`${styles.select} !w-full ${
                                    formData.status === "" && "text-[#979aa1]"
                                }`}
                                value={formData?.status || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        status: e.target.value,
                                    });
                                }}
                            >
                                <option value="">Select Status</option>
                                <option value="active">Active</option>
                                <option value="inactive">Inactive</option>
                            </select>
                        </div>
                        <div className="w-full"></div>
                    </div>

                    <button className={`${styles.button} !mt-4`} type="submit">
                        <FaSave className="mb-0.5" /> Save Retailer
                    </button>
                </form>
            </div>
        </section>
    );
};

export default CreateRetailer;
