import React, { useEffect, useState } from "react";
import BackButtons from "../BackButton";
import styles from "../../styles/styles";
import { FaSave } from "react-icons/fa";
import { getData, postData } from "../../api/Fetch";

const initialState = {
  territory: "",
  beat: "",
};

const CreateTerritoryWiseBeat = () => {
  const [formData, setFormData] = useState(initialState);
  const [territories, setTerritories] = useState([]);
  const [beats, setBeats] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    postData("territory-beats/create-territory-beat", {
      territoryWiseBeat: formData,
    });
    setFormData(initialState);
  };

  useEffect(() => {
    getData("territories", setTerritories);
    getData("beats", setBeats);
  }, []);

  return (
    <section>
      <BackButtons />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">Create Bank</p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory
                <span className="text-red-500">*</span>
              </label>
              <input
                list="territories"
                required
                placeholder="Enter Territory"
                name="territory"
                className={`${styles.input}`}
                value={formData?.territory || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territory: e.target.value,
                  });
                }}
              />
              <datalist id="territories">
                {territories.map((item, index) => (
                  <option key={index} value={item.code}>
                    {`${item.code}  |  ${item.territoryName}`}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Beat
                <span className="text-red-500">*</span>
              </label>
              <input
                list="beats"
                required
                placeholder="Enter Beat"
                name="beat"
                className={`${styles.input}`}
                value={formData?.beat || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    beat: e.target.value,
                  });
                }}
              />
              <datalist id="beats">
                {beats.map((item, index) => (
                  <option key={index} value={item.code}>
                    {`${item.code}  |  ${item.beatName}`}
                  </option>
                ))}
              </datalist>
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Territory wise Beat
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateTerritoryWiseBeat;
