import React, { useEffect, useState } from "react";
import Buttons from "../../components/Buttons";
import { Table } from "../../components/Table";
import { deleteDataByCode, getData } from "../../api/Fetch";
import importFormat from "../../assets/emp-assigns.csv";
import { useNavigate } from "react-router-dom";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin7Line } from "react-icons/ri";

const EmployeeBeatPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  const importPath = "emp-assigns/import-emp-assigns";
  const exportPath = "emp-assigns/export-emp-assigns";
  const importFormatPath = importFormat;

  const handleEdit = (code) => {
    navigate(`edit/${code}`);
  };

  const handleDelete = (code) => {
    deleteDataByCode("emp-assigns", code);
    setReload(!reload);
  };

  useEffect(() => {
    getData("emp-assigns", setData);
  }, [reload]);

  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "SR Code",
      accessor: "srCode",
    },
    {
      Header: "SR Name",
      accessor: "srName",
    },
    {
      Header: "Territory Code",
      accessor: "territoryCode",
    },
    {
      Header: "Territory Name",
      accessor: "territoryName",
    },
    {
      Header: "Beat Code",
      accessor: "beatCode",
    },
    {
      Header: "Beat Name",
      accessor: "beatName",
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "#24b1b7" }}
              onClick={() => {
                handleEdit(cell.row.original.code);
              }}
            >
              <TbEdit />
            </button>
            <button
              className={`text-lg`}
              style={{ color: "#f44336" }}
              onClick={() => {
                handleDelete(cell.row.original.code);
              }}
            >
              <RiDeleteBin7Line />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <section>
      <Buttons
        importPath={importPath}
        exportPath={exportPath}
        importFormatPath={importFormatPath}
      />
      <Table Columns={Columns} Data={data} />
    </section>
  );
};

export default EmployeeBeatPage;
