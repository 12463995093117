import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import BackButton from "../BackButton";
import { GrUpdate } from "react-icons/gr";
import { getDataByCode, updateDataByCode } from "../../api/Fetch";

const EditPermission = () => {
    const [formData, setFormData] = useState({});
    const { code } = useParams();

    const handleSubmit = async (e) => {
        e.preventDefault();
        updateDataByCode("permissions", code, { permission: formData });
    };

    useEffect(() => {
        getDataByCode("permissions", code, setFormData);
    }, [code]);

    return (
        <section>
            <BackButton />

            <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
                <p className="font-semibold uppercase text-[15px] mb-2">
                    Update/Edit Permission
                </p>

                <form onSubmit={handleSubmit} className="space-y-2.5">
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">Name</label>
                        <input
                            type="text"
                            placeholder="Enter PermissionName"
                            name="permissionName"
                            className={`${styles.input}`}
                            value={formData?.permissionName || ""}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    permissionName: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">
                            Status
                        </label>
                        <select
                            placeholder="Status"
                            name="status"
                            className={`${styles.select} !w-full ${
                                formData.status === "" && "text-[#979aa1]"
                            }`}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    status: e.target.value,
                                });
                            }}
                        >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>

                    <button className={`${styles.button} !mt-4`} type="submit">
                        <GrUpdate className="mb-0.5" /> Update Permission
                    </button>
                </form>
            </div>
        </section>
    );
};

export default EditPermission;
