import React, { useEffect, useState } from "react";
import Print from "../../components/Print";
import { Table } from "../../components/Table";
import { format } from "date-fns";
import { getData, updateDataByCode } from "../../api/Fetch";
import { BiShow } from "react-icons/bi";
import FilterOrders from "../../components/FilterOrders";
import styles from "../../styles/styles";
import toast from "react-hot-toast";

const OrderReceivePage = () => {
  // eslint-disable-next-line no-unused-vars
  const [data, setData] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [items, setItems] = useState([]);
  const [index, setIndex] = useState(0);
  const [reload, setReload] = useState(false);

  const handleView = (code, index) => {
    setShowModal(!showModal);
    const temps = orders.filter((order) => order.code === code);
    setItems(temps[0].items);
    setIndex(index);
  };

  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Order ID",
      accessor: "code",
    },
    {
      Header: "Order Date",
      accessor: "createdAt",
      Cell: (cell) => {
        const date = new Date(cell.value);
        return format(date, "dd/MM/yyyy hh:mm a");
      },
    },
    {
      Header: "Principal",
      accessor: "businessName",
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "Order Amount",
      accessor: "totalPrice",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: (cell) => {
        if (cell.value === "not-received") {
          return (
            <span className="text-semibold text-red-600">Not Received</span>
          );
        } else if (cell.value === "received") {
          return <span className="text-semibold text-green-600">Received</span>;
        } else {
          return (
            <span className="text-semibold text-yellow-600">
              Partially Received
            </span>
          );
        }
      },
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "#0d9e47" }}
              onClick={() => {
                handleView(cell.row.original.code, cell.row.index);
              }}
            >
              <BiShow />
            </button>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    getData("orders", setOrders);
  }, [reload, setReload]);

  const ProductColumns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "PrincipalName",
      accessor: "businessName",
    },
    {
      Header: "ProductName",
      accessor: "productName",
    },
    {
      Header: "ProductSize",
      accessor: "packSize",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "ReceivedQuantity",
      Cell: ({ row }) => {
        return (
          <div>
            <input
              type="text"
              placeholder="Input"
              className="dark:bg-[#fff] border rounded-full px-3.5 py-1 focus:outline-none text-center w-28"
              value={
                items.find((product) => product?.code === row.original.code)
                  ?.receivedQuantity || ""
              }
              onChange={(e) => {
                handleAddReceivedQuantity(row.original.code, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "TotalPrice",
      Cell: ({ row }) => {
        return (
          <div>
            {Number(row.original.dp) *
              Number(row.original.receivedQuantity || 0)}
          </div>
        );
      },
    },
    {
      Header: "TotalDue",
      Cell: ({ row }) => {
        return (
          <div>
            {Number(row.original.dp) *
              Number(
                row.original.quantity - row.original.receivedQuantity || 0
              )}
          </div>
        );
      },
    },
  ];

  const handleAddReceivedQuantity = (code, quantity) => {
    const updatedProducts = items.map((product) =>
      product.code === code
        ? {
            ...product,
            receivedQuantity: quantity ? parseInt(quantity) : 0,
          }
        : product
    );
    setItems(updatedProducts);
  };

  const handleSave = () => {
    setShowModal(!showModal);
    orders[index].items = items;

    let temp = 0;
    let res = true;
    for (const item of items) {
      if (item.receivedQuantity > item.quantity) {
        toast.error("Received quantity cannot be greater than quantity");
        res = false;
        break;
      } else if (item.receivedQuantity < 0) {
        toast.error("Quantity cannot be less than 0");
        res = false;
        break;
      } else if (!item.receivedQuantity) {
        toast.error("Quantity cannot be empty");
        res = false;
        break;
      }
      temp += item.receivedQuantity || 0;
    }

    res &&
      updateDataByCode("orders", orders[index].code, {
        order: { flag: parseInt(orders[index].quantity) === parseInt(temp) },
      });

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  return (
    <section>
      <Print />

      <FilterOrders setData={setData} />

      <Table Columns={Columns} Data={orders} />

      {showModal && (
        <div className="modal modal-open absolute rounded-md">
          <div className="modal-box w-11/12 max-w-full bg-[#eff3f4]">
            <button
              onClick={() => setShowModal(!showModal)}
              className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5"
            >
              ✕
            </button>
            <h3 className="font-bold text-lg">Ordered Products</h3>
            <Table Columns={ProductColumns} Data={items} />

            <div className="flex justify-end mr-3 mt-6">
              <p className={`${styles.button} px-10`} onClick={handleSave}>
                Save
              </p>
            </div>
          </div>
        </div>
      )}
    </section>
  );
};

export default OrderReceivePage;
