import React, { useState } from "react";
import styles from "../../styles/styles";
import { FiFilter } from "react-icons/fi";
import { FaDownload } from "react-icons/fa6";
import toast from "react-hot-toast";

const FilterInvoice = ({ setData }) => {
    const [formData, setFormData] = useState({
        dateFrom: "",
        dateTo: "",
    });

    const handleSubmit = async (e) => {
        e.preventDefault();
        // console.log(formData);
    };

    const handleDownload = () => {
        toast.success("Coming soon...");
    };

    return (
        <div className="lg:mx-3">
            <form onSubmit={handleSubmit} className="flex flex-col gap-3">
                <div className="flex gap-4 flex-3">
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">
                            <span>Date From</span>
                        </label>
                        <input
                            type="date"
                            name="dateFrom"
                            className={`${styles.input}`}
                            value={formData?.dateFrom || ""}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    dateFrom: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">
                            <span>Date To</span>
                        </label>
                        <input
                            type="date"
                            name="dateTo"
                            className={`${styles.input}`}
                            value={formData?.dateTo || ""}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    dateTo: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">
                            <span>Beat</span>
                        </label>
                        <select
                            name="beat"
                            className={`${styles.select} !w-full`}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    beat: e.target.value,
                                });
                            }}
                        >
                            <option value="">All Beat</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>
                </div>

                <div className="w-full flex items-center gap-4">
                    <button
                        type="submit"
                        className={`${styles.button} flex-1 h-9`}
                    >
                        <FiFilter /> Filter
                    </button>

                    <button
                        onClick={handleDownload}
                        className={`${styles.button} flex-1 h-9`}
                    >
                        <FaDownload /> Download CSV
                    </button>
                </div>
            </form>
        </div>
    );
};

export default FilterInvoice;
