import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import { GrUpdate } from "react-icons/gr";
import BackButtons from "../BackButton";
import { getData, getDataByCode, updateDataByCode } from "../../api/Fetch";

const EditUser = () => {
  const [formData, setFormData] = useState({});
  const { code } = useParams();
  const [permissions, setPermissions] = useState([]);
  const [selected, setSelected] = useState([]);

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;

    setSelected((prevSelected) => {
      if (checked) {
        return [...prevSelected, name];
      } else {
        return prevSelected.filter((item) => item !== name);
      }
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.permissions = selected;
    updateDataByCode("users", code, { user: formData });
  };

  useEffect(() => {
    getData("permissions", setPermissions);
    getDataByCode("users", code, setFormData);
  }, [code]);

  useEffect(() => {
    setSelected(formData?.permissions || []);
  }, [formData]);

  return (
    <section>
      <BackButtons />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit User
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                User Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter UserName"
                name="userName"
                className={`${styles.input}`}
                value={formData?.userName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    userName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Designation
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter Designation"
                name="designation"
                className={`${styles.input}`}
                value={formData?.designation || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    designation: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Phone Number
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter PhoneNumber"
                name="phone"
                className={`${styles.input}`}
                value={formData?.phone || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    phone: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Email</label>
              <input
                type="email"
                placeholder="Enter Email"
                name="email"
                className={`${styles.input}`}
                value={formData?.email || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    email: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">User Role</label>
              <select
                name="role"
                className={`${styles.select} !w-full ${
                  formData.role === "" && "text-[#979aa1]"
                }`}
                value={formData?.role || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    role: e.target.value,
                  });
                }}
              >
                <option value="">Select Role</option>
                <option value="user">user</option>
                <option value="manager">manager</option>
                <option value="admin">admin</option>
              </select>
            </div>

            <div className="w-full">
              <label className="text-sm font-medium mb-1">Sales Code</label>
              <input
                type="text"
                placeholder="Enter SalesCode"
                name="salesCode"
                className={`${styles.input}`}
                value={formData?.salesCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    salesCode: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Password<span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                required
                placeholder="Enter Password"
                name="password"
                className={`${styles.input}`}
                value={formData?.password || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    password: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Confirm Password
                <span className="text-red-500">*</span>
              </label>
              <input
                type="password"
                required
                placeholder="Enter ConfirmPassword"
                name="confirmPassword"
                className={`${styles.input}`}
                value={formData?.confirmPassword || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    confirmPassword: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Status<span className="text-red-500">*</span>
              </label>
              <select
                placeholder="Status"
                name="status"
                required
                className={`${styles.select} !w-full ${
                  formData.status === "" && "text-[#979aa1]"
                }`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="w-full pt-6">
              <input
                type="checkbox"
                name="isSr"
                id="isSr"
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    isSr: e.target.checked,
                  });
                }}
              />{" "}
              &nbsp;
              <label className="text-[15px]" htmlFor="isSr">
                Is SR?
              </label>
            </div>
          </div>
          <div>
            <label className="text-sm font-medium mb-1">Menu Permissions</label>

            <div className="flex flex-wrap items-center gap-1 mb-8">
              {permissions.map((item, index) => (
                <div key={index} className="w-60 ml-16">
                  <input
                    type="checkbox"
                    checked={selected.includes(item.permissionName)}
                    id={`${item.permissionName}`}
                    name={`${item.permissionName}`}
                    onChange={handleCheckboxChange}
                  />{" "}
                  &nbsp;
                  <label
                    className="text-[15px]"
                    htmlFor={`${item.permissionName}`}
                  >
                    {item.permissionName}
                  </label>
                </div>
              ))}
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update User
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditUser;
