import React, { useState } from "react";
import styles from "../../styles/styles";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";
import { postData } from "../../api/Fetch";

const initialState = {
    permissionName: "",
    status: "active",
};

const CreatePermission = () => {
    const [formData, setFormData] = useState(initialState);

    const handleSubmit = async (e) => {
        e.preventDefault();
        postData("permissions/create-permission", { permission: formData });
        setFormData(initialState);
    };

    return (
        <section>
            <BackButton />

            <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
                <p className="font-semibold uppercase text-[15px] mb-2">
                    Create Permission
                </p>

                <form onSubmit={handleSubmit} className="space-y-2.5">
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">
                            Name<span className="text-red-500">*</span>
                        </label>
                        <input
                            type="text"
                            required
                            placeholder="Enter PermissionName"
                            name="permissionName"
                            className={`${styles.input}`}
                            value={formData?.permissionName || ""}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    permissionName: e.target.value,
                                });
                            }}
                        />
                    </div>
                    <div className="w-full">
                        <label className="text-sm font-medium mb-1">
                            Status<span className="text-red-500">*</span>
                        </label>
                        <select
                            required
                            name="status"
                            className={`${styles.select} !w-full ${
                                formData.status === "" && "text-[#979aa1]"
                            }`}
                            onChange={(e) => {
                                setFormData({
                                    ...formData,
                                    status: e.target.value,
                                });
                            }}
                        >
                            <option value="">Select Status</option>
                            <option value="active">Active</option>
                            <option value="inactive">Inactive</option>
                        </select>
                    </div>

                    <button className={`${styles.button} !mt-4`} type="submit">
                        <FaSave className="mb-0.5" /> Save Permission
                    </button>
                </form>
            </div>
        </section>
    );
};

export default CreatePermission;
