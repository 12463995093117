/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import Print from "../../components/Print";
import FilterPurchasing from "../../components/Purchasing/FilterPurchasing";
import { FaCartFlatbedSuitcase } from "react-icons/fa6";
import {
  HiOutlineCurrencyBangladeshi,
  HiCurrencyBangladeshi,
} from "react-icons/hi";
import { Table } from "../../components/Table";
import { IoIosCreate } from "react-icons/io";
import styles from "../../styles/styles";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { getData } from "../../api/Fetch";
import { RxCross2 } from "react-icons/rx";

const PurchasingPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [finalProducts, setFinalProducts] = useState([]);
  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);

  const handleCreate = async () => {
    await localStorage.setItem("data", JSON.stringify(selectedProducts));

    if (selectedProducts.length === 0) {
      toast.error("You didn't select any product!");
      return;
    }
    navigate("create");
  };

  const handleResetQuantity = (id) => {
    const selectProduct = selectedProducts.find(
      (product) => product?.code === id
    );

    if (selectProduct) {
      selectProduct.quantity = 0;

      const updatedProducts = selectedProducts.filter(
        (product) => product?.code !== id
      );
      setSelectedProducts(updatedProducts);
    } else {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    getData("products", setProducts);
  }, []);

  useEffect(() => {
    setFinalProducts([]);
    data.primary &&
      setFinalProducts(
        products.filter((product) => product.businessCode === data.primary)
      );
    data.primary &&
      data.brand &&
      setFinalProducts(
        products.filter(
          (product) =>
            product.businessCode === data.primary &&
            product.brand === data.brand
        )
      );
    data.primary &&
      data.brand &&
      data.category &&
      setFinalProducts(
        products.filter(
          (product) =>
            product.businessCode === data.primary &&
            product.brand === data.brand &&
            product.category === data.category
        )
      );
  }, [products, data, setData]);

  const handleAddQuantity = (code, quantity) => {
    // filter products by code and if quantity is greater than 0 then add the product to selectedProducts with quantity else remove it from selectedProducts
    const findProduct = finalProducts.find((product) => product?.code === code);

    if (parseInt(quantity) > 0) {
      const updatedProducts = selectedProducts.map((product) =>
        product.code === code
          ? {
              ...product,
              quantity: quantity,
              totalPrice: parseFloat(product.dp) * parseFloat(quantity),
            }
          : product
      );

      const existingProduct = selectedProducts.find(
        (product) => product?.code === code
      );

      if (!existingProduct) {
        updatedProducts.push({
          ...findProduct,
          quantity,
          totalPrice: parseFloat(findProduct.dp) * parseFloat(quantity),
        });
      }

      setSelectedProducts(updatedProducts);
    } else {
      const updatedProducts = selectedProducts.filter(
        (product) => product?.code !== code
      );
      setSelectedProducts(updatedProducts);
    }
  };

  const Columns = [
    {
      Header: "#",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Items",
      accessor: "code",
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
      Header: "Principal",
      accessor: "businessName",
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "DP",
      accessor: "dp",
    },
    {
      Header: "TP",
      accessor: "tp",
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Quantity",
      Cell: ({ row }) => {
        return (
          <div>
            <input
              type="text"
              placeholder="Input"
              className="dark:bg-[#fff] border rounded-full px-3.5 py-1 focus:outline-none text-center w-28"
              value={
                selectedProducts.find(
                  (product) => product?.code === row.original.code
                )?.quantity
              }
              onChange={(e) => {
                handleAddQuantity(row.original.code, e.target.value);
              }}
            />
          </div>
        );
      },
    },
    {
      Header: "Total Price",
      Cell: ({ row }) =>
        parseFloat(
          selectedProducts.find(
            (product) => product?.code === row.original.code
          )?.quantity || 0
        ) * parseFloat(row.original.dp || 0),
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "red" }}
              onClick={() => {
                handleResetQuantity(cell.row.original.code);
              }}
            >
              <RxCross2 />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <section>
      <Print />

      <FilterPurchasing data={data} setData={setData} />

      {/* stats */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:mx-3 my-3 max-w-7xl">
        {/* card */}
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Items</p>
            <p className="font-bold text-2xl">{selectedProducts.length}</p>
          </div>
          <FaCartFlatbedSuitcase size={38} />
        </div>
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Purchase Price</p>
            <p className="font-bold text-2xl">
              {selectedProducts.reduce(
                (total, product) =>
                  total +
                  parseFloat(product?.quantity || 0) *
                    parseFloat(product?.dp || 0),
                0
              )}
            </p>
          </div>
          <HiOutlineCurrencyBangladeshi size={38} />
        </div>
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Dealer Price</p>
            <p className="font-bold text-2xl">
              {selectedProducts.reduce(
                (total, product) => total + parseFloat(product?.dp || 0),
                0
              )}
            </p>
          </div>
          <HiCurrencyBangladeshi size={38} />
        </div>
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Due</p>
            <p className="font-bold text-2xl">0</p>
          </div>
          <HiCurrencyBangladeshi size={38} />
        </div>
      </div>

      <Table Columns={Columns} Data={finalProducts} />

      <div className="lg:mx-3 mt-6 bottom-0 flex justify-end mb-10">
        <button
          onClick={handleCreate}
          className={`${styles.button} px-8 !bg-[#0d9e47]`}
        >
          <IoIosCreate /> Create
        </button>
      </div>

      {selectedProducts.length > 0 && (
        <Table Columns={SelectedColumns} Data={selectedProducts} />
      )}
    </section>
  );
};

export default PurchasingPage;

const SelectedColumns = [
  {
    Header: "#",
    Cell: ({ row }) => row.index + 1,
  },
  {
    Header: "Items",
    accessor: "code",
  },
  {
    Header: "Product Name",
    accessor: "productName",
  },
  {
    Header: "Principal",
    accessor: "businessName",
  },
  {
    Header: "Brand",
    accessor: "brand",
  },
  {
    Header: "DP",
    accessor: "dp",
  },
  {
    Header: "TP",
    accessor: "tp",
  },
  {
    Header: "MRP",
    accessor: "mrp",
  },
  {
    Header: "Quantity",
    accessor: "quantity",
  },
  {
    Header: "Total Price",
    accessor: "totalPrice",
  },
];
