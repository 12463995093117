import React, { useEffect, useState } from "react";
import BackButtons from "../BackButton";
import styles from "../../styles/styles";
import { GrUpdate } from "react-icons/gr";
import { useParams } from "react-router-dom";
import { getData, getDataByCode, updateDataByCode } from "../../api/Fetch";

const EditDistributorWiseTerritory = () => {
  const [formData, setFormData] = useState({});
  const [territories, setTerritories] = useState([]);
  const [distributors, setDistributors] = useState([]);
  const { code } = useParams();

  useEffect(() => {
    getDataByCode("distributor-territories", code, setFormData);
    getData("territories", setTerritories);
    getData("distributors", setDistributors);
  }, [code]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateDataByCode("distributor-territories", code, {
      distributorWiseTerritory: formData,
    });
  };

  return (
    <section>
      <BackButtons />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit Distributor Wise Territory
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Distributor
                <span className="text-red-500">*</span>
              </label>
              <input
                list="distributors"
                required
                placeholder="Enter Distributor"
                name="distributor"
                className={`${styles.input}`}
                value={formData?.distributor || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    distributor: e.target.value,
                  });
                }}
              />
              <datalist id="distributors">
                {distributors.map((item, index) => (
                  <option key={index} value={item.code}>
                    {`${item.code}  |  ${item.distributorName}`}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory
                <span className="text-red-500">*</span>
              </label>
              <input
                list="territories"
                required
                placeholder="Enter Territory"
                name="territory"
                className={`${styles.input}`}
                value={formData?.territory || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territory: e.target.value,
                  });
                }}
              />
              <datalist id="territories">
                {territories.map((item, index) => (
                  <option key={index} value={item.code}>
                    {`${item.code}  |  ${item.territoryName}`}
                  </option>
                ))}
              </datalist>
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update Territory wise Beat
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditDistributorWiseTerritory;
