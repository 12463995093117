import React, { useEffect, useState } from "react";
import BackButton from "../BackButton";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import { FaSave } from "react-icons/fa";
import { district } from "../../static/district";
import { division } from "../../static/division";
import { thana } from "../../static/thana";

const initialState = {
  zone: "",
  district: "",
  subDistrict: "",
  beatName: "",
  beatDescription: "",
  approxOutlets: 0,
  territory: "",
  status: "active",
};

const CreateBeat = () => {
  const [formData, setFormData] = useState(initialState);
  const [territory, setTerritory] = useState("");
  const [territories, setTerritories] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.approxOutlets = parseInt(formData.approxOutlets);
    postData("beats/create-beat", { beat: formData });
    setFormData(initialState);
    setTerritory("");
  };

  useEffect(() => {
    getData("territories", setTerritories);
  }, [setTerritories]);

  useEffect(() => {
    const territory = territories.find(
      (territory) => territory.code === formData.territory
    );

    if (territory) {
      setTerritory(territory.territoryName);
    }
  }, [territories, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">Create Beat</p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Zone (Division)
                <span className="text-red-500">*</span>
              </label>
              <input
                list="division"
                type="text"
                required
                placeholder="Enter ZoneName"
                name="zone"
                className={`${styles.input}`}
                value={formData?.zone || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    zone: e.target.value,
                  });
                }}
              />
              <datalist id="division">
                {division.map((item) => (
                  <option key={item} value={item} />
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                District<span className="text-red-500">*</span>
              </label>
              <input
                list="district"
                type="text"
                required
                placeholder="Enter District"
                name="district"
                className={`${styles.input}`}
                value={formData?.district || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    district: e.target.value,
                  });
                }}
              />
              <datalist id="district">
                {district.map((item) => (
                  <option key={item} value={item} />
                ))}
              </datalist>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Thana (Sub-District)<span className="text-red-500">*</span>
              </label>
              <input
                list="thana"
                type="text"
                placeholder="Enter ThanaName"
                name="subDistrict"
                className={`${styles.input}`}
                value={formData?.subDistrict || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    subDistrict: e.target.value,
                  });
                }}
              />
              <datalist id="thana">
                {thana.map((item) => (
                  <option key={item} value={item} />
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Beat Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter BeatName"
                name="beatName"
                className={`${styles.input}`}
                value={formData?.beatName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    beatName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Beat Description<span className="text-red-500">*</span>
              </label>
              <textarea
                required
                placeholder="Enter BeatDescription"
                name="beatDescription"
                className={`${styles.textarea}`}
                value={formData?.beatDescription || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    beatDescription: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Approximate Outlets
              </label>
              <input
                type="number"
                placeholder="Enter ApproximateOutlets"
                name="approxOutlets"
                className={`${styles.input}`}
                value={formData?.approxOutlets || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    approxOutlets: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Status<span className="text-red-500">*</span>
              </label>
              <select
                placeholder="Status"
                required
                name="status"
                className={`${styles.select} !w-full ${
                  formData.status === "" && "text-[#979aa1]"
                }`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory <span className="text-red-500">*</span>
              </label>
              <input
                list="territories"
                type="text"
                required
                placeholder="Enter Territory"
                name="territory"
                className={`${styles.input}`}
                value={territory || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territory: e.target.value,
                  });
                  setTerritory(e.target.value);
                }}
              />
              <datalist id="territories">
                {territories.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code} | {item.territoryName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full"></div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Beat
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateBeat;
