import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";

const initialState = {
  distributorCode: "",
  srCode: "",
  beatCode: "",
  visitDate: "",
};

const CreateSRBeatPlan = () => {
  const [formData, setFormData] = useState(initialState);
  const [distributorList, setDistributorList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [beatList, setBeatList] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    postData("sr-beat-plans/create-sr-beat-plan", { srBeatPlan: formData });
    setFormData(initialState);
  };

  useEffect(() => {
    getData("distributors", setDistributorList);
    getData("employees", setEmployeesList);
    getData("beats", setBeatList);
  }, []);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create SR Beat Plan
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="employees"
                type="text"
                required
                placeholder="Enter EmployeeCode"
                name="srCode"
                className={`${styles.input}`}
                value={formData?.srCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    srCode: e.target.value,
                  });
                }}
              />
              <datalist id="employees">
                {employeesList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.srName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Distributor Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="distributors"
                type="text"
                required
                placeholder="Enter DistributorCode"
                name="distributorCode"
                className={`${styles.input}`}
                value={formData?.distributorCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    distributorCode: e.target.value,
                  });
                }}
              />
              <datalist id="distributors">
                {distributorList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.distributorName}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Beat Code<span className="text-red-500">*</span>
              </label>
              <input
                list="beats"
                type="text"
                required
                placeholder="Enter BeatCode"
                name="beatCode"
                className={`${styles.input}`}
                value={formData?.beatCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    beatCode: e.target.value,
                  });
                }}
              />
              <datalist id="beats">
                {beatList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.beatName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Visit Day
                <span className="text-red-500">*</span>
              </label>
              <select
                required
                placeholder="Enter VisitDate"
                name="visitDate"
                className={`${styles.input}`}
                value={formData?.visitDate || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    visitDate: e.target.value,
                  });
                }}
              >
                <option value="">Select Visit Date</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </select>
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save SR Beat Plan
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateSRBeatPlan;
