import React from "react";

const statData = [
    {
        title: "Today's Total Sale",
        value: "0.00",
    },
    {
        title: "Today's Collection",
        value: "0.00",
    },
    {
        title: "Today's Due",
        value: "0.00",
    },
];

const Dashboard = () => {
    return (
        <div className="w-full grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-3 sm:gap-4 px-3 py-2">
            {statData.map(({ title, value }, index) => (
                <div key={index} className="bg-[#fff] p-4 rounded-lg">
                    <h3 className="font-semibold text-xl mb-4 text-[#3a76a3]">
                        {title}
                    </h3>
                    <p className="font-bold text-4xl">৳ {value}</p>
                </div>
            ))}
        </div>
    );
};

export default Dashboard;
