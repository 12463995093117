import React, { useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

const MyAdvMarker = ({ item, setMarkerRef, setInfoWindowOpen }) => {
  const ref = useCallback(
    (marker) => setMarkerRef(marker, item.code),
    [setMarkerRef, item.code]
  );

  return (
    <>
      <AdvancedMarker
        ref={ref}
        onClick={() => setInfoWindowOpen(item)}
        position={{
          lat: parseFloat(item.location.lat),
          lng: parseFloat(item.location.long),
        }}
      >
        <div className="w-4 h-4 rounded-full bg-blue-600 border-[3px] border-blue-300"></div>
      </AdvancedMarker>

      {/* {infoWindowOpen && (
        <InfoWindow
          anchor={10}
          position={{
            lat: parseFloat(item.location.lat),
            lng: parseFloat(item.location.long),
          }}
          onCloseClick={() => setInfoWindowOpen(false)}
        >
          <div className="flex flex-col gap-0.5">
            <p className="text-xs font-medium">Customer Code: {item?.code}</p>
            <p className="text-xs font-medium">Retailer Name: {item?.retailerName}</p>
            <p className="text-xs font-medium">Channel Type: {channelTypes[item?.channelType]}</p>
          </div>
        </InfoWindow>
      )} */}
    </>
  );
};

export default MyAdvMarker;
