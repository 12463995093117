import React, { useEffect, useState } from "react";
import BackButton from "../BackButton";
import { getData, getDataByCode, updateDataByCode } from "../../api/Fetch";
import { useParams } from "react-router-dom";
import styles from "../../styles/styles";
import { GrUpdate } from "react-icons/gr";

const EditDistributor = () => {
  const [formData, setFormData] = useState({});
  const [territory, setTerritory] = useState("");
  const [territories, setTerritories] = useState([]);
  const { code } = useParams();

  useEffect(() => {
    getDataByCode("distributors", code, setFormData);
    getData("territories", setTerritories);
  }, [code]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateDataByCode("distributors", code, { distributor: formData });
  };

  useEffect(() => {
    const territory = territories.find(
      (territory) => territory.code === formData.territory
    );

    if (territory) {
      setTerritory(territory.territoryName);
    }
  }, [territories, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit Distributor
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Distributor Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter DistributorName"
                name="distributorName"
                className={`${styles.input}`}
                value={formData?.distributorName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    distributorName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Address</label>
              <input
                type="text"
                placeholder="Enter Address"
                name="address"
                className={`${styles.input}`}
                value={formData?.address || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Proprietor</label>
              <input
                type="text"
                placeholder="Enter ProprietorName"
                name="proprietor"
                className={`${styles.input}`}
                value={formData?.proprietor || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    proprietor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Contact Person</label>
              <input
                type="text"
                placeholder="Enter ContactPerson"
                name="contactPerson"
                className={`${styles.input}`}
                value={formData?.contactPerson || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    contactPerson: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Telephone</label>
              <input
                type="text"
                placeholder="Enter Telephone"
                name="telephone"
                className={`${styles.input}`}
                value={formData?.telephone || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    telephone: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Construction of Warehouse
              </label>
              <select
                name="warehouseConstruction"
                className={`${styles.select} !w-full ${
                  formData.warehouseConstruction === "" && "text-[#979aa1]"
                }`}
                value={formData?.warehouseConstruction || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    warehouseConstruction: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="pacca">Pacca</option>
                <option value="semi-pacca">Semi Pacca</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Area of Warehouse
              </label>
              <input
                type="text"
                placeholder="Enter AreaOfWarehouse"
                name="warehouseArea"
                className={`${styles.input}`}
                value={formData?.warehouseArea || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    warehouseArea: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Number of Vehicles
              </label>
              <input
                type="text"
                placeholder="Enter NumberOfVehicles"
                name="vehicleNumber"
                className={`${styles.input}`}
                value={formData?.vehicleNumber || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    vehicleNumber: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Type of Vehicles
              </label>
              <input
                type="text"
                placeholder="Enter TypeOfVehicles"
                name="vehicleType"
                className={`${styles.input}`}
                value={formData?.vehicleType || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    vehicleType: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Number of Manpower
              </label>
              <input
                type="text"
                placeholder="Enter NumberofManpower"
                name="manpowerNumber"
                className={`${styles.input}`}
                value={formData?.manpowerNumber || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    manpowerNumber: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Application of LHP
              </label>
              <select
                name="applicationLhp"
                className={`${styles.select} !w-full ${
                  formData.applicationLhp === "" && "text-[#979aa1]"
                }`}
                value={formData?.applicationLhp || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    applicationLhp: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="will-be-submitted">Will be submitted</option>
              </select>
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Bank Solvency Certificate
              </label>
              <select
                name="bankCertificate"
                className={`${styles.select} !w-full ${
                  formData.bankCertificate === "" && "text-[#979aa1]"
                }`}
                value={formData?.bankCertificate || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    bankCertificate: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="will-be-submitted">Will be submitted</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">Trade License</label>
              <select
                name="tradeLicense"
                className={`${styles.select} !w-full ${
                  formData.tradeLicense === "" && "text-[#979aa1]"
                }`}
                value={formData?.tradeLicense || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    tradeLicense: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="will-be-submitted">Will be submitted</option>
              </select>
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">Bank Statement</label>
              <select
                name="bankStatement"
                className={`${styles.select} !w-full ${
                  formData.bankStatement === "" && "text-[#979aa1]"
                }`}
                value={formData?.bankStatement || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    bankStatement: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
                <option value="will-be-submitted">Will be submitted</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Nature of Business
              </label>
              <input
                type="text"
                placeholder="Enter NatureOfBusiness"
                name="natureOfBusiness"
                className={`${styles.input}`}
                value={formData?.natureOfBusiness || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    natureOfBusiness: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Year of Establishment
              </label>
              <input
                type="text"
                placeholder="Enter YearOfEstablishment"
                name="establishmentYear"
                className={`${styles.input}`}
                value={formData?.establishmentYear || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    establishmentYear: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Product Applied for
              </label>
              <input
                type="text"
                placeholder="Enter ProductAppliedFor"
                name="appliedFor"
                className={`${styles.input}`}
                value={formData?.appliedFor || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    appliedFor: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Current Product Assortment
              </label>
              <input
                type="text"
                placeholder="Enter ProductAssortment"
                name="productAssortment"
                className={`${styles.input}`}
                value={formData?.productAssortment || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    productAssortment: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Yearly Turnover
              </label>
              <input
                type="text"
                placeholder="Enter YearlyTurnover"
                name="yearlyTurnover"
                className={`${styles.input}`}
                value={formData?.yearlyTurnover || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    yearlyTurnover: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Potential for monthly purchase
              </label>
              <input
                type="text"
                placeholder="Enter MonthlyPurchase"
                name="monthlyPurchase"
                className={`${styles.input}`}
                value={formData?.monthlyPurchase || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    monthlyPurchase: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Current Credit Facility
              </label>
              <select
                name="creditFacility"
                className={`${styles.select} !w-full ${
                  formData.creditFacility === "" && "text-[#979aa1]"
                }`}
                value={formData?.creditFacility || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    creditFacility: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="yes">Yes</option>
                <option value="no">No</option>
              </select>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Discounts of Current Products
              </label>
              <input
                type="text"
                placeholder="Enter ProductsDiscount"
                name="productsDiscount"
                className={`${styles.input}`}
                value={formData?.productsDiscount || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    productsDiscount: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Monthly Target</label>
              <input
                type="text"
                placeholder="Enter (Product wise, in units & Taka)"
                name="monthlyTarget"
                className={`${styles.input}`}
                value={formData?.monthlyTarget || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    monthlyTarget: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Mode of Payment
              </label>
              <select
                name="paymentMode"
                className={`${styles.select} !w-full ${
                  formData.paymentMode === "" && "text-[#979aa1]"
                }`}
                value={formData?.paymentMode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    paymentMode: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="cash">Cash</option>
                <option value="dd">DD</option>
                <option value="po">PO</option>
                <option value="credit">Credit</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Product Delivery
              </label>
              <select
                name="productDelivery"
                className={`${styles.select} !w-full ${
                  formData.productDelivery === "" && "text-[#979aa1]"
                }`}
                value={formData?.productDelivery || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    productDelivery: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="company will deliver">
                  Company will deliver
                </option>
                <option value="party will collect">Party will collect</option>
              </select>
            </div>
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Carrying Charge Allowed
              </label>
              <select
                placeholder="In Special Case-Describe"
                name="carryingChargeAllowed"
                className={`${styles.select} !w-full ${
                  formData.carryingChargeAllowed === "" && "text-[#979aa1]"
                }`}
                value={formData?.carryingChargeAllowed || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    carryingChargeAllowed: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="allowed">Allowed</option>
                <option value="not allowed">Not Allowed</option>
              </select>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">Status</label>
              <select
                name="status"
                className={`${styles.select} !w-full ${
                  formData.status === "" && "text-[#979aa1]"
                }`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select an option</option>
                <option value="active">active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Activation Date
              </label>
              <input
                type="date"
                name="activationDate"
                className={`${styles.input}`}
                value={formData?.activationDate || ""}
                onChange={(e) => {
                  setFormData({ ...formData, activationDate: e.target.value });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Territory</label>
              <input
                list="territories"
                type="text"
                required
                placeholder="Enter Territory"
                name="territory"
                className={`${styles.input}`}
                value={territory || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territory: e.target.value,
                  });
                  setTerritory(e.target.value);
                }}
              />
              <datalist id="territories">
                {territories.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code} | {item.territoryName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Territory Date</label>
              <input
                type="date"
                name="territoryDate"
                className={`${styles.input}`}
                value={formData?.territoryDate || ""}
                onChange={(e) => {
                  setFormData({ ...formData, territoryDate: e.target.value });
                }}
              />
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update Distributor
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditDistributor;
