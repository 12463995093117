import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import BackButton from "../BackButton";
import { GrUpdate } from "react-icons/gr";
import { getData, getDataByCode, updateDataByCode } from "../../api/Fetch";

const EditBrand = () => {
  const [formData, setFormData] = useState({});
  const [principals, setPrincipals] = useState([]);
  const [principal, setPrincipal] = useState("");
  const { code } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateDataByCode("brands", code, { brand: formData });
  };

  useEffect(() => {
    getDataByCode("brands", code, setFormData);
    getData("principals", setPrincipals);
  }, [code]);

  useEffect(() => {
    const newPrincipal = principals.find(
      (principal) => principal.code === formData.principal
    );

    if (newPrincipal) {
      setPrincipal(newPrincipal.principalName);
    }
  }, [principals, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit Brand
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="w-full flex gap-4 items-center">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Principal<span className="text-red-500">*</span>
              </label>
              <input
                list="principals"
                type="text"
                required
                placeholder="Enter Principal"
                name="principal"
                className={`${styles.input}`}
                value={principal || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    principal: e.target.value,
                  });
                  setPrincipal(e.target.value);
                }}
              />
              <datalist id="principals">
                {principals?.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code} | {item.principalName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Brand Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter BrandName"
                name="brandName"
                className={`${styles.input}`}
                value={formData?.brandName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    brandName: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update Brand
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditBrand;
