import React, { useEffect } from "react";
import { useMemo } from "react";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  usePagination,
} from "react-table";
import styles from "../styles/styles";
import { FaSearch } from "react-icons/fa";
import { IoIosArrowUp, IoIosArrowDown } from "react-icons/io";
import { RiExpandUpDownLine } from "react-icons/ri";
import {
  MdKeyboardDoubleArrowLeft,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdKeyboardDoubleArrowRight,
} from "react-icons/md";

export const Table = ({ Columns, Data }) => {
  // eslint-disable-next-line
  const columns = useMemo(() => {
    return [
      ...Columns,
      // {
      //     Header: "Action",
      //     Cell: ({ cell }) => {
      //         return (
      //             <div className="flex items-center gap-3">
      //                 {handles.map((handle, index) => (
      //                     <button
      //                         key={index}
      //                         className={`text-lg`}
      //                         style={{ color: handle.color }}
      //                         onClick={() => {
      //                             handle.handle(cell.row.original.code);
      //                         }}
      //                     >
      //                         {handle.name === "View" && <GoEye />}
      //                         {handle.name === "Edit" && <TbEdit />}
      //                         {handle.name === "Delete" && (
      //                             <RiDeleteBin7Line />
      //                         )}
      //                     </button>
      //                 ))}
      //             </div>
      //         );
      //     },
      // },
    ];
  }, [Columns]);

  // eslint-disable-next-line
  const data = useMemo(() => Data, [Data]);

  const tableInstance = useTable(
    { columns: columns, data },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    pageOptions,
    prepareRow,
    state,
    gotoPage,
    pageCount,
    setPageSize,
    setGlobalFilter,
  } = tableInstance;

  const { globalFilter, pageIndex, pageSize } = state;

  useEffect(() => {
    setPageSize(25);
  }, [setPageSize]);

  return (
    <section className="mt-2.5 lg:px-3">
      <div className="flex justify-between items-center mb-2">
        <div className="text-base flex items-center gap-1.5">
          <span>Show </span>
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className={`${styles.select}`}
          >
            {[10, 25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </select>
          <span> entries</span>
        </div>

        <div className="relative flex items-center gap-1.5">
          <span>Search</span>
          <input
            type="text"
            placeholder="Search..."
            value={globalFilter || ""}
            onChange={(e) => setGlobalFilter(e.target.value)}
            className={`${styles.input}`}
          />
          <FaSearch className="text-gray-400 absolute right-3 top-2.5" />
        </div>
      </div>

      <table {...getTableProps()} className="w-full !rounded-lg">
        <thead className="bg-[#fff] text-[17px]">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()} className="border-b-2">
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  className="px-4 py-4 text-left font-semibold capitalize"
                >
                  <div className="flex items-center justify-between">
                    {column.render("Header")}
                    <span>
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <span>
                            <IoIosArrowUp className="h-3 text-gray-300" />
                            <IoIosArrowDown className="h-3 -mt-[5px]" />
                          </span>
                        ) : (
                          <span>
                            <IoIosArrowUp className="h-3 -mb-[5px]" />
                            <IoIosArrowDown className="h-3 text-gray-300" />
                          </span>
                        )
                      ) : (
                        <span className="text-gray-300">
                          <RiExpandUpDownLine />
                        </span>
                      )}
                    </span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()} className="bg-[#f8f9fd] text-[15px]">
          {page.length === 0 ? (
            <tr className="border-b-[1px]">
              <td colSpan={Columns.length} className="px-4 py-2">
                No Data Found
              </td>
            </tr>
          ) : null}
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="border-b-[1px]">
                {row.cells.map((cell) => {
                  return (
                    <td {...cell.getCellProps()} className="px-4 py-2">
                      {cell.render("Cell")}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>

      <div className="flex items-center py-2 bg-[#fff] text-[#fff] px-4">
        <div className="flex gap-2 items-center text-xl font-bold">
          <span
            className={`bg-[#24b1b7] p-1 rounded-full ${
              canPreviousPage
                ? "cursor-pointer"
                : "cursor-not-allowed bg-[#979797]"
            }`}
            title="First Page"
            onClick={() => gotoPage(0)}
          >
            <MdKeyboardDoubleArrowLeft />
          </span>
          <span
            className={`bg-[#24b1b7] p-1 rounded-full ${
              canPreviousPage
                ? "cursor-pointer"
                : "cursor-not-allowed bg-[#979797]"
            }`}
            title="Previous"
            onClick={() => previousPage()}
          >
            <MdKeyboardArrowLeft />
          </span>
        </div>

        <p className="flex-1 flex justify-center text-[#373737]">
          Page : &nbsp; <b>{pageIndex + 1}</b> &nbsp;of&nbsp;{" "}
          <b>{pageOptions.length}</b>{" "}
        </p>

        <div className="flex gap-2 items-center text-xl font-bold">
          <span
            className={`bg-[#0d9e47] p-1 rounded-full ${
              canNextPage
                ? "cursor-pointer"
                : "cursor-not-allowed !bg-[#979797]"
            }`}
            onClick={() => nextPage()}
          >
            <MdKeyboardArrowRight />
          </span>
          <span
            className={`bg-[#0d9e47] p-1 rounded-full ${
              canNextPage
                ? "cursor-pointer"
                : "cursor-not-allowed !bg-[#979797]"
            }`}
            onClick={() => gotoPage(pageCount - 1)}
          >
            <MdKeyboardDoubleArrowRight />
          </span>
        </div>
      </div>
    </section>
  );
};
