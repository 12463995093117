import React, { useEffect, useState } from "react";
import Buttons from "../../components/Buttons";
import { useNavigate } from "react-router-dom";
import { Table } from "../../components/Table";
import importFormat from "../../assets/bankInfos.csv";
import { deleteDataByCode, getData } from "../../api/Fetch";

const BankPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  const importPath = "bank-infos/import-bank-infos";
  const exportPath = "bank-infos/export-bank-infos";
  const importFormatPath = importFormat;

  const handleEdit = (code) => {
    navigate(`edit/${code}`);
  };

  const handleDelete = (code) => {
    deleteDataByCode("bank-infos", code);
    setReload(!reload);
  };

  const handles = [
    {
      color: "#24b1b7",
      handle: handleEdit,
      name: "Edit",
    },
    {
      color: "#f44336",
      handle: handleDelete,
      name: "Delete",
    },
  ];

  useEffect(() => {
    getData("bank-infos", setData);
  }, [reload]);

  return (
    <section>
      <Buttons
        importPath={importPath}
        exportPath={exportPath}
        importFormatPath={importFormatPath}
      />

      <Table Columns={Columns} Data={data} handles={handles} />
    </section>
  );
};

export default BankPage;

const Columns = [
  {
    Header: "#",
    Cell: ({ cell }) => {
      return cell.row.index + 1;
    },
  },
  {
    Header: "Principal",
    accessor: "businessName",
  },
  {
    Header: "A/CNo",
    accessor: "accountNo",
  },
  {
    Header: "A/CName",
    accessor: "accountName",
  },
  {
    Header: "BankName",
    accessor: "bankName",
  },
  {
    Header: "BranchName",
    accessor: "branchName",
  },
  {
    Header: "Address",
    accessor: "address",
  },
  {
    Header: "SwiftCode",
    accessor: "swiftCode",
  },
  {
    Header: "RoutingNo",
    accessor: "routingNumber",
  },
  {
    Header: "District",
    accessor: "district",
  },
];
