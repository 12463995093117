import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import BackButtons from "../BackButton";
import { GrUpdate } from "react-icons/gr";
import { getDataByCode, updateDataByCode } from "../../api/Fetch";

const EditTerritory = () => {
  const [formData, setFormData] = useState({});
  const { code } = useParams();

  useEffect(() => {
    getDataByCode("territories", code, setFormData);
  }, [code]);

  const handleSubmit = (e) => {
    e.preventDefault();

    updateDataByCode("territories", code, { territory: formData });
  };

  return (
    <section>
      <BackButtons />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit Territory
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex flex-col gap-2.5 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter TerritoryName"
                name="territoryName"
                className={`${styles.input}`}
                value={formData?.territoryName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territoryName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory Description<span className="text-red-500">*</span>
              </label>
              <textarea
                required
                placeholder="Enter TerritoryDescription"
                name="territoryDescription"
                className={`${styles.textarea}`}
                value={formData?.territoryDescription || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territoryDescription: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                <span>Status</span>
              </label>
              <select
                placeholder="Status"
                name="status"
                className={`${styles.select} !w-full ${
                  formData.status === "" && "text-[#979aa1]"
                }`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="w-full"></div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update Territory
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditTerritory;
