import React, { useEffect, useState } from "react";
import Print from "../../components/Print";
import { FaCartFlatbedSuitcase } from "react-icons/fa6";
import {
  HiOutlineCurrencyBangladeshi,
  HiCurrencyBangladeshi,
} from "react-icons/hi";
import { Table } from "../../components/Table";
import styles from "../../styles/styles";
import toast from "react-hot-toast";
import { MdOutlinePayments } from "react-icons/md";
import { getData, postData } from "../../api/Fetch";
import { color } from "../../static/data";
import { RiDeleteBin7Line } from "react-icons/ri";

const CreatePurchasing = () => {
  const [showModal, setShowModal] = useState(false);
  const [data, setData] = useState([]);
  const [formData, setFormData] = useState({});

  useEffect(() => {
    const products = JSON.parse(localStorage.getItem("data"));
    setData(products);
  }, []);

  const handleDelete = (id) => {
    setData(data.filter((d) => d.code !== id));
    toast.success("Deleted successfully");
  };

  const handles = [
    {
      name: "Delete",
      handle: handleDelete,
      color: color.delete,
    },
  ];

  const Columns = [
    {
      Header: "#",
      Cell: ({ row }) => row.index + 1,
    },
    {
      Header: "Items",
      accessor: "code",
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
      Header: "Principal",
      accessor: "businessName",
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "DP",
      accessor: "dp",
    },
    {
      Header: "TP",
      accessor: "tp",
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Quantity",
      accessor: "quantity",
    },
    {
      Header: "Total Price",
      accessor: "totalPrice",
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "red" }}
              onClick={() => {
                handleDelete(cell.row.original.code);
              }}
            >
              <RiDeleteBin7Line />
            </button>
          </div>
        );
      },
    },
  ];

  // random 4 digit number generate
  const random = Math.floor(1000 + Math.random() * 9000);

  // Group data by brand and calculate total quantity, total price, and _id array for each group
  const groupedData = data.reduce((acc, item) => {
    const businessName = item.businessName;
    const brand = item.brand;
    const existingGroup = acc.find((group) => group.brand === brand);

    if (existingGroup) {
      existingGroup.quantity += parseInt(item.quantity, 10);
      existingGroup.totalPrice += item.totalPrice;
      existingGroup.items.push(item);
    } else {
      acc.push({
        businessName,
        brand,
        quantity: parseInt(item.quantity, 10),
        totalPrice: item.totalPrice,
        items: [item],
      });
    }

    return acc;
  }, []);

  const handleOrder = (random) => {
    // setShowModal(false);

    // merge groupedData and formData
    const mergedData = groupedData.map((group, index) => ({
      ...group,
      bankCode: formData[index] || "",
      code: `${random}_${index + 1}`,
    }));

    postData("orders/create-order", { order: mergedData });

    localStorage.removeItem("data");
    window.history.back();
  };

  return (
    <section>
      <Print />

      {/* stats */}
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-4 lg:mx-3 my-3 max-w-7xl">
        {/* card */}
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Items</p>
            <p className="font-bold text-2xl">{data.length}</p>
          </div>
          <FaCartFlatbedSuitcase size={38} />
        </div>
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Purchase Price</p>
            <p className="font-bold text-2xl">
              {data.reduce((a, b) => a + parseFloat(b.totalPrice), 0)}
            </p>
          </div>
          <HiOutlineCurrencyBangladeshi size={38} />
        </div>
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Dealer Price</p>
            <p className="font-bold text-2xl">
              {data.reduce((a, b) => a + parseFloat(b.dp), 0)}
            </p>
          </div>
          <HiCurrencyBangladeshi size={38} />
        </div>
        <div className="bg-[#fff] p-4 px-6 rounded-lg flex items-center justify-between">
          <div className="">
            <p className="font-semibold">Total Due</p>
            <p className="font-bold text-2xl">0</p>
          </div>
          <HiCurrencyBangladeshi size={38} />
        </div>
      </div>

      <div className="my-6 lg:mx-3">
        <p className="text-lg">
          PO Order No : <span className="font-bold">{random}</span>
        </p>
      </div>

      <Table Columns={Columns} Data={data} handles={handles} />

      <div className="lg:mx-3 mt-6 bottom-0 flex justify-end">
        <button
          className={`${styles.button} px-8 !bg-[#0d9e47]`}
          onClick={() => setShowModal(!showModal)}
        >
          <MdOutlinePayments /> Make Payment
        </button>

        {showModal && (
          <div className="modal modal-open absolute rounded-md">
            <div className="modal-box w-11/12 max-w-full bg-[#eff3f4]">
              <button
                onClick={() => setShowModal(!showModal)}
                className="btn btn-sm btn-circle btn-ghost absolute right-5 top-5"
              >
                ✕
              </button>
              <h3 className="font-bold text-lg">Payment References</h3>
              <NewTable
                data={groupedData}
                formData={formData}
                setFormData={setFormData}
              />

              <div className="w-full flex flex-col">
                <label htmlFor="file" className="text-sm font-bold mb-1">
                  Attach File
                </label>
                <input
                  type="file"
                  name="file"
                  id="file"
                  className="dark:bg-white w-full input input-xs px-1.5 py-1.5 input-bordered focus:outline-none focus:border-[#24b1b7] focus:border-[1px] rounded-md h-9 text-sm max-w-[740px]"
                />
              </div>

              <div className="mt-3 bottom-0 flex justify-end">
                <button
                  className={`${styles.button} px-8 !bg-[#0d9e47]`}
                  onClick={() => handleOrder(random)}
                >
                  <MdOutlinePayments /> Confirm Payment & Print
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default CreatePurchasing;

const NewTable = ({ data, formData, setFormData }) => {
  const [bankList, setBankList] = useState([]);

  useEffect(() => {
    getData("bank-infos", setBankList);
  }, []);

  return (
    <table className="w-full !rounded-lg mt-8 mb-6">
      <thead className="bg-[#fff] text-[17px]">
        <tr className="border-b-2">
          <th className="px-4 py-4 text-left font-semibold capitalize">#</th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Principal
          </th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Brand
          </th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Total Item
          </th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Total Bill (A)
          </th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Advance Payment (B)
          </th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Net Payable (A-B)
          </th>
          <th className="px-4 py-4 text-left font-semibold capitalize">
            Payment Info
          </th>
        </tr>
      </thead>
      <tbody className="bg-[#f8f9fd] text-[15px]">
        {data.map((item, index) => (
          <tr key={index} className="border-b-[1px]">
            <td className="px-4 py-2">{index + 1}</td>
            <td className="px-4 py-2">{item.businessName}</td>
            <td className="px-4 py-2">{item.brand}</td>
            <td className="px-4 py-2">{item.quantity}</td>
            <td className="px-4 py-2">{item.totalPrice}</td>
            <td className="px-4 py-2">0</td>
            <td className="px-4 py-2">{item.totalPrice}</td>
            <td className="px-4 py-2">
              <select
                name="bank"
                className={`${styles.select} w-fit`}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    [index]: e.target.value,
                  });
                }}
              >
                <option value="">Select bank</option>
                {bankList.map((bank, index) => {
                  return (
                    item.businessName === bank.principal && (
                      <option key={index} value={bank.code}>
                        {`${bank.bankName} | A/C: ${bank.accountNo}`}
                      </option>
                    )
                  );
                })}
              </select>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
};
