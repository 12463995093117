import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";

const initialState = {
  srCode: "",
  territoryCode: "",
  beatCode: "",
};

const CreateEmployeeBeat = () => {
  const [formData, setFormData] = useState(initialState);
  const [employeesList, setEmployeesList] = useState([]);
  const [territoriesList, setTerritoriesList] = useState([]);
  const [beatsList, setBeatsList] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.mobile = parseInt(formData.mobile);
    postData("emp-assigns/create-emp-assign", { empAssign: formData });
    setFormData(initialState);
  };

  useEffect(() => {
    getData("employees", setEmployeesList);
    getData("territories", setTerritoriesList);
    getData("beats", setBeatsList);
  }, []);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Assign Employee to Territory and Beat
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="employees"
                type="text"
                required
                placeholder="Enter EmployeeCode"
                name="srCode"
                className={`${styles.input}`}
                value={formData?.srCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    srCode: e.target.value,
                  });
                }}
              />
              <datalist id="employees">
                {employeesList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.srName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full"></div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="territories"
                type="text"
                required
                placeholder="Enter TerritoryCode"
                name="territoryCode"
                className={`${styles.input}`}
                value={formData?.territoryCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territoryCode: e.target.value,
                  });
                }}
              />
              <datalist id="territories">
                {territoriesList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.territoryName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Beat / Route Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="beats"
                type="text"
                required
                placeholder="Enter BeatCode"
                name="beatCode"
                className={`${styles.input}`}
                value={formData?.beatCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    beatCode: e.target.value,
                  });
                }}
              />
              <datalist id="beats">
                {beatsList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.beatName}
                  </option>
                ))}
              </datalist>
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Assign Employee
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateEmployeeBeat;
