import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { FaSearch } from "react-icons/fa";
import { getData } from "../../api/Fetch";

const FilterOrderList = ({ data, setData }) => {
  const [business, setBusiness] = useState([]);
  const [formData, setFormData] = useState({
    primary: "",
    search: "",
  });

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     console.log(formData);
  //   };

  useEffect(() => {
    getData("principals", setBusiness);
    setData({
      primary: "",
      search: "",
    });
  }, [setBusiness, setData]);

  return (
    <div className="mt-2.5 lg:mx-3">
      <form className="flex gap-3 items-end max-w-4xl">
        <div className="flex-auto flex gap-3">
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium mb-1">
              <span>Principal</span>
            </label>
            <select
              name="primary"
              className={`${styles.select} !w-full ${
                data.primary === "" && "text-[#979aa1]"
              }`}
              onChange={(e) => {
                setData({
                  ...data,
                  primary: e.target.value,
                });
              }}
            >
              <option value="">Select a principal</option>
              {business.map((item) => (
                <option key={item._id} value={item.principalName}>
                  {item.principalName}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium mb-1">
              <span>Search</span>
            </label>
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                value={formData.search || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    search: e.target.value,
                  });
                }}
                className={`${styles.input}`}
              />
              <FaSearch className="text-gray-400 absolute right-3 top-2.5" />
            </div>
          </div>
        </div>
        {/* <button type="submit" className={`${styles.button} h-9`}>
          <FiFilter /> Filter
        </button> */}
      </form>
    </div>
  );
};

export default FilterOrderList;
