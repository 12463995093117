import React, { useState } from "react";
import styles from "../styles/styles";
import { TbDatabaseImport, TbDatabaseExport } from "react-icons/tb";
import { MdFormatListBulleted, MdAddCircleOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import FileImport from "./FileImport";
import axios from "axios";
import toast from "react-hot-toast";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const Buttons = ({ importPath, exportPath, importFormatPath }) => {
  const navigate = useNavigate();
  const [importing, setImporting] = useState(false);

  const handleImport = () => {
    setImporting(true);
  };

  const handleExport = async () => {
    try {
      await axios.post(`${BASE_URL}/${exportPath}`);

      toast.success("Exported successfully");
    } catch (error) {
      toast.error(error?.response?.data?.message);
    }
  };

  const handleCreate = () => {
    navigate("create");
  };

  return (
    <section>
      <div className="flex items-center py-2 lg:px-3">
        <div className="flex-1 flex gap-2">
          <button onClick={handleImport} className={`${styles.button}`}>
            <TbDatabaseImport className="mt-0.5" /> Import
          </button>
          <button onClick={handleExport} className={`${styles.button}`}>
            <TbDatabaseExport className="mt-0.5" /> Export
          </button>
          <a download href={importFormatPath} className={`${styles.button}`}>
            <MdFormatListBulleted className="mt-0.5" /> Import Format
          </a>
        </div>
        <div className="flex-1 flex gap-2 justify-end">
          <button onClick={handleCreate} className={`${styles.button}`}>
            <MdAddCircleOutline className="mt-0.5" /> Create
          </button>
        </div>
      </div>
      <hr className="border-gray-300 mt-1" />

      {importing && (
        <FileImport path={importPath} setImporting={setImporting} />
      )}
    </section>
  );
};

export default Buttons;
