import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";

const initialState = {
    retailerCode: "",
    dueAmount: 0,
};

const CreateRetailerDue = () => {
    const [formData, setFormData] = useState(initialState);
    const [retailerList, setRetailerList] = useState([]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        formData.dueAmount = parseInt(formData.dueAmount);
        postData("retailer-dues/create-retailer-due", { retailerDue: formData });
        setFormData(initialState);
    };

    useEffect(() => {
        getData("retailers", setRetailerList);
    }, []);

    return (
        <section>
            <BackButton />

            <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
                <p className="font-semibold uppercase text-[15px] mb-2">
                    Create Retailer Due
                </p>

                <form onSubmit={handleSubmit} className="space-y-2.5">
                    <div className="flex gap-4 items-center w-full">
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Retailer Code
                                <span className="text-red-500">*</span>
                            </label>
                            <input
                                list="retailers"
                                type="text"
                                required
                                placeholder="Enter RetailerCode"
                                name="retailerCode"
                                className={`${styles.input}`}
                                value={formData?.retailerCode || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        retailerCode: e.target.value,
                                    });
                                }}
                            />
                            <datalist id="retailers">
                                {retailerList.map((item, index) => (
                                    <option key={index} value={item.code}>
                                        {item.code +
                                            " | " +
                                            item.retailerName}
                                    </option>
                                ))}
                            </datalist>
                        </div>
                        <div className="w-full">
                            <label className="text-sm font-medium mb-1">
                                Due Amount<span className="text-red-500">*</span>
                            </label>
                            <input
                                type="number"
                                required
                                placeholder="Enter DueAmount"
                                name="dueAmount"
                                className={`${styles.input}`}
                                value={formData?.dueAmount || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        dueAmount: e.target.value,
                                    });
                                }}
                            />
                        </div>
                    </div>

                    <button className={`${styles.button} !mt-4`} type="submit">
                        <FaSave className="mb-0.5" /> Save Retailer Due
                    </button>
                </form>
            </div>
        </section>
    );
};

export default CreateRetailerDue;
