import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";
import { getData, postData } from "../../api/Fetch";
import { Table } from "../Table";

const initialState = {
  principal: "",
  brandName: "",
};

const CreateBrand = () => {
  const [formData, setFormData] = useState(initialState);
  const [principals, setPrincipals] = useState([]);
  const [data, setData] = useState([]);
  const [principal, setPrincipal] = useState("");
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getData("brands", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    postData("brands/create-brand", { brand: formData });
    setFormData(initialState);

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  useEffect(() => {
    getData("principals", setPrincipals);
  }, []);

  useEffect(() => {
    const newPrincipal = principals.find(
      (principal) => principal.code === formData.principal
    );

    if (newPrincipal) {
      setPrincipal(newPrincipal.principalName);
    }
  }, [principals, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">Create Brand</p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="w-full flex gap-4 items-center">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Principal<span className="text-red-500">*</span>
              </label>
              <input
                list="principal"
                type="text"
                required
                placeholder="Enter Principal"
                name="principal"
                className={`${styles.input}`}
                value={principal || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    principal: e.target.value,
                  });
                  setPrincipal(e.target.value);
                }}
              />
              <datalist id="principal">
                {principals?.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code} | {item.principalName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Brand Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter BrandName"
                name="brandName"
                className={`${styles.input}`}
                value={formData?.brandName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    brandName: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Brand
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <BrandList data={data} />
      </div>
    </section>
  );
};

export default CreateBrand;

const BrandList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "PrincipalCode",
      accessor: "principalCode",
    },
    {
      Header: "PrincipalName",
      accessor: "principalName",
    },
    {
      Header: "BrandCode",
      accessor: "code",
    },
    {
      Header: "BrandName",
      accessor: "brandName",
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
