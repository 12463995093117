import React, { useEffect, useState } from "react";
// import BackButtons from "../BackButton";
import styles from "../../styles/styles";
import { FaSave } from "react-icons/fa";
import { getData } from "../../api/Fetch";
// import { getData, postData } from "../../api/Fetch";

const initialState = {
  principal: "",
  accountNo: 0,
  routingNumber: 0,
  accountName: "",
  bankName: "",
  branchName: "",
  address: "",
  swiftCode: "",
  district: "",
};

const CreateBank = ({ bankInfo, setBankInfo }) => {
  const [formData, setFormData] = useState(initialState);
  const [bankData, setBankData] = useState([]);
  const [value, setValue] = useState("");

  const handleSetBankInfo = () => {
    formData.accountNo = parseInt(formData.accountNo);
    formData.routingNumber = parseInt(formData.routingNumber);

    setBankInfo([...bankInfo, formData]);
    setFormData(initialState);
  };

  const handleChange = (value) => {
    setValue(value);

    const bank = bankData.find(
      (bank) => parseInt(value) === parseInt(bank.routingNo)
    );

    if (bank) {
      setFormData({
        ...formData,
        routingNumber: bank.routingNo,
        bankName: bank.bankName,
        branchName: bank.branchName,
        district: bank.districtName,
      });
    } else {
      setFormData({
        ...formData,
        routingNumber: value,
        bankName: "",
        branchName: "",
        district: "",
      });
    }
  };

  useEffect(() => {
    getData("bank-data", setBankData);
  }, []);

  return (
    <section>
      <div className="bg-[#fff] p-4 rounded-lg mt-2.5">
        <form className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Account Number
                <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                placeholder="Enter AccountNumber"
                name="accountNo"
                className={`${styles.input}`}
                value={formData?.accountNo || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    accountNo: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Account Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter AccountName"
                name="accountName"
                className={`${styles.input}`}
                value={formData?.accountName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    accountName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Routing Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter RoutingNumber"
                name="routingNumber"
                className={`${styles.input}`}
                value={value || ""}
                onChange={(e) => {
                  handleChange(e.target.value);
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Bank Name
                {/* <span className="text-red-500">*</span> */}
              </label>
              <input
                type="text"
                required
                placeholder="Enter BankName"
                name="bankName"
                className={`${styles.input}`}
                value={formData?.bankName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    bankName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Branch Name
                {/* <span className="text-red-500">*</span> */}
              </label>
              <input
                type="text"
                required
                placeholder="Enter BranchName"
                name="branchName"
                className={`${styles.input}`}
                value={formData?.branchName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    branchName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                District
                {/* <span className="text-red-500">*</span> */}
              </label>
              <input
                type="text"
                required
                placeholder="Enter District"
                name="district"
                className={`${styles.input}`}
                value={formData?.district || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    district: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          {/* <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Address
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter Address"
                name="address"
                className={`${styles.input}`}
                value={formData?.address || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    address: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Swift Code</label>
              <input
                type="text"
                placeholder="Enter SwiftCode"
                name="swiftCode"
                className={`${styles.input}`}
                value={formData?.swiftCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    swiftCode: e.target.value,
                  });
                }}
              />
            </div>
          </div> */}

          <p className={`${styles.button} !mt-4`} onClick={handleSetBankInfo}>
            <FaSave className="mb-0.5" /> Save Bank
          </p>
        </form>
      </div>
    </section>
  );
};

export default CreateBank;
