import React from "react";
import { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import Buttons from "../../components/Buttons";
import { deleteDataByCode, getData } from "../../api/Fetch";
import importFormat from "../../assets/departments.csv";
import { RiDeleteBin7Line } from "react-icons/ri";

const DepartmentPage = () => {
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  const importPath = "departments/import-departments";
  const exportPath = "departments/export-departments";
  const importFormatPath = importFormat;

  const handleDelete = (code) => {
    deleteDataByCode("departments", code);
    setReload(!reload);
  };

  useEffect(() => {
    getData("departments", setData);
  }, [reload]);

  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Department Code",
      accessor: "code",
    },
    {
      Header: "Department Name",
      accessor: "departmentName",
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "#f44336" }}
              onClick={() => {
                handleDelete(cell.row.original.code);
              }}
            >
              <RiDeleteBin7Line />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <section>
      <Buttons
        importPath={importPath}
        exportPath={exportPath}
        importFormatPath={importFormatPath}
      />
      <Table Columns={Columns} Data={data} />
    </section>
  );
};

export default DepartmentPage;
