import React, { useEffect, useState } from "react";
import FilterInvoice from "../../components/Invoice/FilterInvoice";
import { Table } from "../../components/Table";
import { getData } from "../../api/Fetch";

const InvoicePage = () => {
  const [data, setData] = useState([]);

  useEffect(() => {
    getData("orders/invoices", setData);
  }, []);

  return (
    <section>
      <FilterInvoice setData={setData} />

      <Table Columns={Columns} Data={data} />
    </section>
  );
};

export default InvoicePage;

const Columns = [
  {
    Header: "#",
    Cell: ({ row }) => row.index + 1,
  },
  {
    Header: "Invoice No",
    accessor: "code",
  },
  {
    Header: "Customer Code",
    accessor: "customerCode",
  },
  {
    Header: "Customer Name",
    accessor: "customerName",
  },
  {
    Header: "Beat Code",
    accessor: "beatCode",
  },
  {
    Header: "Beat Name",
    accessor: "beatName",
  },
  {
    Header: "Order Date",
    accessor: "createdAt",
  },
  {
    Header: "Total Price",
    accessor: "totalPrice",
  },
  {
    Header: "Deliver Status",
    accessor: "status",
  },
  {
    Header: "Due Amount",
    accessor: "dueAmount",
  },
];
