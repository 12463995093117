import React from "react";
import { useEffect, useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Navbar from "../components/Navbar";
import DashboardMenuItem from "../components/DashboardMenuItem";
import { DashboardMenu } from "../static/data";
import { logo } from "../static/images";
import { RiMenuFoldLine } from "react-icons/ri";

const Layout = () => {
  const [isShowMenu, setShowMenu] = useState(true);
  const [drawerSide, setDrawerSide] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    if (!sessionStorage.getItem("token")) {
      navigate("/login");
    }
  });

  const handleResize = () => {
    setShowMenu(window.innerWidth > 1024);
    window.innerWidth < 1024 ? setDrawerSide("drawer-side") : setDrawerSide("");
  };

  window.addEventListener("resize", handleResize);

  const menuItem = DashboardMenu;

  return (
    <section className="p-1">
      <div className="drawer drawer-mobile rounded-lg h-[calc(100vh-0.5rem)]">
        <input
          id="dashboard-sidebar"
          type="checkbox"
          checked={isShowMenu === true ? "checked" : ""}
          className="drawer-toggle"
        />
        <div
          className={`drawer-content rounded-lg ${
            isShowMenu === true ? "ml-1" : "ml-0"
          }`}
        >
          <Navbar isShowMenu={isShowMenu} setShowMenu={setShowMenu} />

          <div className="h-[calc(100vh-4.75rem)] overflow-y-scroll relative">
            <Outlet />
          </div>
        </div>
        {isShowMenu && (
          <div className={`${drawerSide} rounded-lg`}>
            <label
              htmlFor="dashboard-sidebar"
              className="drawer-overlay"
            ></label>
            <ul className="relative py-3 overflow-y-hidden w-[17rem] bg-[#3a76a3] text-white rounded-lg">
              <div className="absolute top-4 right-4 lg:hidden">
                <RiMenuFoldLine
                  onClick={() => setShowMenu(!isShowMenu)}
                  className="h-5 w-5 cursor-pointer"
                />
              </div>

              {/* company logo */}
              <div className="w-fit bg-[#fff] p-4 rounded-full border-4 mx-auto">
                <img
                  src={logo}
                  alt="logo"
                  className="w-16 h-16 object-contain"
                />
              </div>
              <hr className="my-3" />
              {/* <!-- Sidebar content here --> */}
              <div className="h-[calc(100vh-10.05rem)] overflow-y-auto no-scrollbar pb-2">
                {menuItem.map((menu, idx) => (
                  <div key={idx} className="pl-4 mb-4">
                    <p className="uppercase text-sm font-bold mb-2">
                      {menu.title}
                    </p>
                    <ul className="space-y-1.5 ml-2">
                      {menu.children.map((child, index) => (
                        <DashboardMenuItem
                          key={index}
                          text={child.text}
                          route={child.route}
                          icon={child.icon}
                        />
                      ))}
                    </ul>
                  </div>
                ))}
              </div>
            </ul>
          </div>
        )}
      </div>
    </section>
  );
};

export default Layout;
