import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import BackButton from "../BackButton";
import { getData, getDataByCode, updateDataByCode } from "../../api/Fetch";
import { GrUpdate } from "react-icons/gr";

const EditSRBeatPlan = () => {
  const [formData, setFormData] = useState({});
  const [distributorList, setDistributorList] = useState([]);
  const [employeesList, setEmployeesList] = useState([]);
  const [beatList, setBeatList] = useState([]);
  const { code } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateDataByCode("sr-beat-plans", code, { srBeatPlan: formData });
  };

  useEffect(() => {
    getDataByCode("sr-beat-plans", code, setFormData);
    getData("distributors", setDistributorList);
    getData("employees", setEmployeesList);
    getData("beats", setBeatList);
  }, [code]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit SR Beat Plan
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Code<span className="text-red-500">*</span>
              </label>
              <input
                list="employees"
                type="text"
                required
                placeholder="Enter EmployeeCode"
                name="srCode"
                className={`${styles.input}`}
                value={formData?.srCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    srCode: e.target.value,
                  });
                }}
              />
              <datalist id="employees">
                {employeesList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.srName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Distributor Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="distributors"
                type="text"
                required
                placeholder="Enter DistributorCode"
                name="distributorCode"
                className={`${styles.input}`}
                value={formData?.distributorCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    distributorCode: e.target.value,
                  });
                }}
              />
              <datalist id="distributors">
                {distributorList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.distributorName}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Beat Code<span className="text-red-500">*</span>
              </label>
              <input
                list="beats"
                type="text"
                required
                placeholder="Enter BeatCode"
                name="beatCode"
                className={`${styles.input}`}
                value={formData?.beatCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    beatCode: e.target.value,
                  });
                }}
              />
              <datalist id="beats">
                {beatList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.beatName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Visit Day
                <span className="text-red-500">*</span>
              </label>
              <select
                required
                placeholder="Enter VisitDate"
                name="visitDate"
                className={`${styles.input}`}
                value={formData?.visitDate || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    visitDate: e.target.value,
                  });
                }}
              >
                <option value="">Select Visit Date</option>
                <option value="Monday">Monday</option>
                <option value="Tuesday">Tuesday</option>
                <option value="Wednesday">Wednesday</option>
                <option value="Thursday">Thursday</option>
                <option value="Friday">Friday</option>
                <option value="Saturday">Saturday</option>
                <option value="Sunday">Sunday</option>
              </select>
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update SR Beat Plan
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditSRBeatPlan;
