import React from "react";
import styles from "../../styles/styles";
import { MdAddCircleOutline } from "react-icons/md";
// import { useNavigate } from "react-router-dom";
import toast from "react-hot-toast";

const UserRequestButton = () => {
    // const navigate = useNavigate();

    const handleCreate = () => {
        toast.success("Refreshed successfully");
    };

    return (
        <section>
            <div className="flex items-center py-2 lg:px-3">
                <div className="flex-1 flex gap-2"></div>
                <div className="flex-1 flex gap-2 justify-end">
                    <button
                        onClick={handleCreate}
                        className={`${styles.button}`}
                    >
                        <MdAddCircleOutline className="mt-0.5" /> Refresh
                    </button>
                </div>
            </div>
            <hr className="border-gray-300 mt-1" />
        </section>
    );
};

export default UserRequestButton;
