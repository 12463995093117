import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";
import { Table } from "../Table";

const initialState = {
  productCode: "",
  dp: 0,
  tp: 0,
  mrp: 0,
  date: "",
};

const CreateProductWisePrice = () => {
  const [formData, setFormData] = useState(initialState);
  const [productList, setProductList] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getData("product-prices-history", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    formData.dp = parseInt(formData.dp);
    formData.tp = parseInt(formData.tp);
    formData.mrp = parseInt(formData.mrp);

    postData("product-prices/create-product-price", {
      productWisePrice: formData,
    });
    setFormData(initialState);

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  useEffect(() => {
    getData("products", setProductList);
  }, []);

  useEffect(() => {
    const newData = data.filter((d) => d.productCode === formData.productCode);
    setFilteredData(newData);
  }, [data, setData, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create Product Wise Price
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 w-full items-center">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Product Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="products"
                type="text"
                required
                placeholder="Enter ProductCode"
                name="productCode"
                className={`${styles.input}`}
                value={formData?.productCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    productCode: e.target.value,
                  });
                }}
              />
              <datalist id="products">
                {productList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.productName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                DP<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                placeholder="Enter DealerPrice"
                name="dp"
                className={`${styles.input}`}
                value={formData?.dp || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    dp: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                TP<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                placeholder="Enter TradePrice"
                name="tp"
                className={`${styles.input}`}
                value={formData?.tp || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    tp: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                MRP
                <span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                placeholder="Enter MRP"
                name="mrp"
                className={`${styles.input}`}
                value={formData?.mrp || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    mrp: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Date<span className="text-red-500">*</span>
              </label>
              <input
                type="date"
                placeholder="Enter Date"
                name="date"
                className={`${styles.input}`}
                value={formData?.date || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    date: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full"></div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Product Wise Price
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <ProductPriceList data={filteredData} />
      </div>
    </section>
  );
};

export default CreateProductWisePrice;

const ProductPriceList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "ProductCode",
      accessor: "productCode",
    },
    {
      Header: "ProductName",
      accessor: "productName",
    },
    {
      Header: "DP",
      accessor: "dp",
    },
    {
      Header: "TP",
      accessor: "tp",
    },
    {
      Header: "MRP",
      accessor: "mrp",
    },
    {
      Header: "Updated At",
      accessor: "date",
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
