import React from "react";

const NotFound = () => {
    return (
        <section className="min-h-screen flex justify-center items-center">
            <div className="text-center">
                <h1 className="font-black text-4xl">
                    Oops..! 404 Page Not Found
                </h1>
                <p className="text-xl mt-2">
                    Looks like you came to wrong page on our server
                </p>
            </div>
        </section>
    );
};

export default NotFound;
