import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";
import { getData, postData } from "../../api/Fetch";
import { Table } from "../Table";

const initialState = {
  principal: "",
  brand: "",
  categoryName: "",
};

const CreateCategory = () => {
  const [formData, setFormData] = useState(initialState);
  const [principals, setPrincipals] = useState([]);
  const [principal, setPrincipal] = useState("");
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getData("categories", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    postData("categories/create-category", { category: formData });
    setFormData(initialState);
    setPrincipal("");
    setBrand("");

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  useEffect(() => {
    getData("principals", setPrincipals);
    getData("brands", setBrands);
  }, []);

  useEffect(() => {
    const newPrincipal = principals.find(
      (principal) => principal.code === formData.principal
    );

    if (newPrincipal) {
      setPrincipal(newPrincipal.principalName);
    }
  }, [principals, formData]);

  useEffect(() => {
    const newBrand = brands.find((brand) => brand.code === formData.brand);

    if (newBrand) {
      setBrand(newBrand.brandName);
    }
  }, [brands, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create Category
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="w-full flex gap-4 items-center">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Principal<span className="text-red-500">*</span>
              </label>
              <input
                list="principals"
                type="text"
                required
                placeholder="Enter Principal"
                name="principal"
                className={`${styles.input}`}
                value={principal || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    principal: e.target.value,
                  });
                  setPrincipal(e.target.value);
                }}
              />
              <datalist id="principals">
                {principals?.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code} | {item.principalName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Brand<span className="text-red-500">*</span>
              </label>
              <input
                list="brands"
                type="text"
                required
                placeholder="Enter Brand"
                name="brand"
                className={`${styles.input}`}
                value={brand || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    brand: e.target.value,
                  });
                  setBrand(e.target.value);
                }}
              />
              <datalist id="brands">
                {brands?.map((item, index) => {
                  return (
                    item.principalCode === formData.principal && (
                      <option key={index} value={item.code}>
                        {item.code} | {item.brandName}
                      </option>
                    )
                  );
                })}
              </datalist>
            </div>
          </div>
          <div className="w-full flex gap-4 items-center">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Category Name<span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter CategoryName"
                name="categoryName"
                className={`${styles.input}`}
                value={formData?.categoryName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    categoryName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full"></div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Category
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <CategoryList data={data} />
      </div>
    </section>
  );
};

export default CreateCategory;

const CategoryList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Principal",
      accessor: "principalName",
    },
    {
      Header: "Brand",
      accessor: "brandName",
    },
    {
      Header: "CategoryName",
      accessor: "categoryName",
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
