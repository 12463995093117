/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import Maps from "../Maps/Maps";
import styles from "../../styles/styles";
import { getData } from "../../api/Fetch";
import axios from "axios";
import { TbCurrentLocation } from "react-icons/tb";
import toast from "react-hot-toast";

// const initialState = {
//   srId: "",
//   date: "",
//   retailerId: "",
//   channelType: "",
// };

const initialState = {
  firstStep: "srs",
  srId: "",
  date: "",
  status: "",
  channelType: "",
};

const MapsPage = () => {
  const [formData, setFormData] = useState(initialState);
  const [srs, setSrs] = useState([]);
  const [channels, setChannels] = useState([]);
  const [orders, setOrders] = useState([]);
  const [ordersData, setOrdersData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);

  // map state
  const [zoom, setZoom] = useState(10);
  const [bounds, setBounds] = useState(null);

  useEffect(() => {
    setLoading(true);
    getData("employees", setSrs);
    getData("channel-types", setChannels);
    handleOrders();
    setLoading(false);
  }, [setLoading]);

  const handleOrders = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/fortyfivedegree/get_orders_for_map`
      );
      setOrders(response.data.data);
    } catch (error) {}
  };

  const handleRetailers = async (value) => {
    setLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/api/v1/retailers/map?${value}`
      );
      setData(response.data.data);
      setOrdersData([]);
    } catch (error) {}
    setLoading(false);
  };

  // const handleSubmit = () => {
  //   if (formData.srId === "") {
  //     setOrdersData([...orders]);
  //     setData([]);
  //     return;
  //   }

  //   if (formData.srId && !formData.date) {
  //     setOrdersData(orders.filter((order) => order.srCode === formData.srId));
  //     setData([]);
  //     return;
  //   }

  //   if (formData.srId && formData.date) {
  //     setOrdersData(
  //       orders.filter(
  //         (order) =>
  //           order.srCode === formData.srId && order.orderedAt === formData.date
  //       )
  //     );
  //     setData([]);
  //     return;
  //   }

  //   if (formData.channelType === "" || formData.retailerId === "") {
  //     handleRetailers("");
  //     return;
  //   }

  //   if (formData.channelType) {
  //     handleRetailers(`CT=${formData.channelType}`);
  //     return;
  //   }

  //   if (formData.retailerId) {
  //     handleRetailers(`R=${formData.retailerId}`);
  //     return;
  //   }
  // };

  const handleSubmit = () => {
    if (formData.firstStep === "srs") {
      toast.success("Not implemented yet...");
      return;
    }

    if (formData.firstStep === "orders") {
      setData([]);
      if (!formData.date && !formData.status) {
        setOrdersData([...orders]);
      } else if (formData.date && !formData.status) {
        setOrdersData(
          orders.filter((order) => order.orderedAt === formData.date)
        );
      } else if (!formData.date && formData.status) {
        setOrdersData(
          orders.filter((order) => order.status === formData.status)
        );
      } else if (formData.date && formData.status) {
        setOrdersData(
          orders.filter(
            (order) =>
              order.orderedAt === formData.date &&
              order.status === formData.status
          )
        );
      }
      return;
    }

    if (formData.firstStep === "retailers") {
      if (formData.channelType === "" || !formData.channelType) {
        handleRetailers(
          `south=${bounds.south}&north=${bounds.north}&west=${bounds.west}&east=${bounds.east}`
        );
      } else if (formData.channelType !== "") {
        handleRetailers(
          `CT=${formData.channelType}&south=${bounds.south}&north=${bounds.north}&west=${bounds.west}&east=${bounds.east}`
        );
      }
      return;
    }
  };

  console.log(bounds);

  return (
    <div className="relative">
      {loading && <div className="absolute left-1/2">Loading...</div>}
      {/* <div className="flex gap-1 items-center w-full">
        <select
          value={formData.srId || ""}
          onChange={(e) => setFormData({ srId: e.target.value })}
          className={`${styles.select} w-20`}
        >
          <option value="">All SRs</option>
          {srs.map((sr, index) => {
            return (
              <option key={index} value={sr.code}>
                {sr.srName}
              </option>
            );
          })}
        </select>
        <input
          disabled={!formData.srId}
          value={formData.date}
          onChange={(e) => setFormData({ ...formData, date: e.target.value })}
          className={`${styles.input} max-w-48 disabled:bg-white disabled:text-black disabled:border-0`}
          type="date"
        />
        <select
          value={formData.retailerId || ""}
          onChange={(e) => setFormData({ retailerId: e.target.value })}
          className={`${styles.select} w-20`}
        >
          <option value="">All Retailers</option>
          {retailers.map((retailer, index) => {
            return (
              <option key={index} value={retailer.code}>
                {retailer.retailerName}
              </option>
            );
          })}
        </select>
        <select
          value={formData.channelType || ""}
          onChange={(e) => setFormData({ channelType: e.target.value })}
          className={`${styles.select} w-20`}
        >
          <option value="">All Channels</option>
          {channels.map((channel, index) => {
            return (
              <option key={index} value={channel.code}>
                {channel.channelType}
              </option>
            );
          })}
        </select>
        <button onClick={handleSubmit} className={`${styles.button} h-[34px]`}>
          <TbCurrentLocation />
          Submit
        </button>
      </div>
      */}

      <div className="flex gap-1 items-center w-full">
        <select
          value={formData.firstStep}
          onChange={(e) => setFormData({ firstStep: e.target.value })}
          className={`${styles.select} w-20`}
        >
          <option value="srs">SRs</option>
          <option value="orders">Orders</option>
          <option value="retailers">Retailers</option>
        </select>

        {/* srs */}
        {formData.firstStep === "srs" && (
          <>
            <select
              value={formData.srId || ""}
              onChange={(e) =>
                setFormData({ ...formData, srId: e.target.value })
              }
              className={`${styles.select} w-20`}
            >
              <option value="">All SRs</option>
              {srs.map((sr, index) => {
                return (
                  <option key={index} value={sr.code}>
                    {sr.srName}
                  </option>
                );
              })}
            </select>
            <input
              disabled={!formData.srId}
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
              className={`${styles.input} max-w-48 disabled:bg-white disabled:text-black disabled:border-0`}
              type="date"
            />
          </>
        )}

        {/* orders */}
        {formData.firstStep === "orders" && (
          <>
            <input
              value={formData.date}
              onChange={(e) =>
                setFormData({ ...formData, date: e.target.value })
              }
              className={`${styles.input} max-w-96`}
              type="date"
            />
            <select
              value={formData.status || ""}
              onChange={(e) =>
                setFormData({ ...formData, status: e.target.value })
              }
              className={`${styles.select} w-20`}
            >
              <option value="">Select Status</option>
              <option value="delivered">Delivered</option>
              <option value="partially-delivered">Partially Delivered</option>
              <option value="not-delivered">Not Delivered</option>
            </select>
          </>
        )}

        {/* retailers */}
        {formData.firstStep === "retailers" && (
          <select
            value={formData.channelType || ""}
            onChange={(e) =>
              setFormData({ ...formData, channelType: e.target.value })
            }
            className={`${styles.select} w-20`}
          >
            <option value="">All Channels</option>
            {channels.map((channel, index) => {
              return (
                <option key={index} value={channel.code}>
                  {channel.channelType}
                </option>
              );
            })}
          </select>
        )}

        <button onClick={handleSubmit} className={`${styles.button} h-[34px]`}>
          <TbCurrentLocation />
          Submit
        </button>
      </div>
      <Maps
        zoom={zoom}
        setZoom={setZoom}
        setBounds={setBounds}
        orders={ordersData}
        data={data}
      />
    </div>
  );
};

export default MapsPage;
