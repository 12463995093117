import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { useParams } from "react-router-dom";
import BackButton from "../BackButton";
import { GrUpdate } from "react-icons/gr";
import { getDataByCode, updateDataByCode } from "../../api/Fetch";

const EditChannelType = () => {
  const [formData, setFormData] = useState({});
  const { code } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    updateDataByCode("channel-types", code, { channelType: formData });
  };

  useEffect(() => {
    getDataByCode("channel-types", code, setFormData);
  }, [code]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Update/Edit Channel Type
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="w-full">
            <label className="text-sm font-medium mb-1">Name</label>
            <input
              type="text"
              required
              placeholder="Enter ChannelType"
              name="channelType"
              className={`${styles.input}`}
              value={formData?.channelType || ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  channelType: e.target.value,
                });
              }}
            />
          </div>
          <div className="w-full">
            <label className="text-sm font-medium mb-1">Description</label>
            <textarea
              required
              placeholder="Enter ChannelDescription"
              name="channelDescription"
              className={`${styles.textarea}`}
              value={formData?.channelDescription || ""}
              onChange={(e) => {
                setFormData({
                  ...formData,
                  channelDescription: e.target.value,
                });
              }}
            />
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <GrUpdate className="mb-0.5" /> Update Channel Type
          </button>
        </form>
      </div>
    </section>
  );
};

export default EditChannelType;
