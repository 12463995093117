import React from "react";
import { useEffect, useState } from "react";
import { Table } from "../../components/Table";
import { useNavigate } from "react-router-dom";
import Buttons from "../../components/Buttons";
import { deleteDataByCode, getData } from "../../api/Fetch";
import importFormat from "../../assets/users.csv";
import { TbEdit } from "react-icons/tb";
import { RiDeleteBin7Line } from "react-icons/ri";

const UserManagerPage = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  const importPath = "users/import-users";
  const exportPath = "users/export-users";
  const importFormatPath = importFormat;

  const handleEdit = (code) => {
    navigate(`edit/${code}`);
  };

  const handleDelete = (code) => {
    deleteDataByCode("users", code);
    setReload(!reload);
  };

  useEffect(() => {
    getData("users", setData);
  }, [reload]);

  const Columns = [
    {
      Header: "#",
      Cell: ({ row }) => {
        return row.index + 1;
      },
    },
    {
      Header: "User ID",
      accessor: "code",
    },
    {
      Header: "User Name",
      accessor: "userName",
    },
    {
      Header: "Designation",
      accessor: "designation",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        return cell.value === "active" ? (
          <span className="bg-green-500 text-white px-2 py-0.5 rounded-full font-semibold text-xs">
            Active
          </span>
        ) : cell.value === "inactive" ? (
          <span className="bg-red-500 text-white px-2 py-0.5 rounded-full font-semibold text-xs">
            Inactive
          </span>
        ) : (
          ""
        );
      },
    },
    {
      Header: "Action",
      Cell: ({ cell }) => {
        return (
          <div className="flex items-center gap-3">
            <button
              className={`text-lg`}
              style={{ color: "#24b1b7" }}
              onClick={() => {
                handleEdit(cell.row.original.code);
              }}
            >
              <TbEdit />
            </button>
            <button
              className={`text-lg`}
              style={{ color: "#f44336" }}
              onClick={() => {
                handleDelete(cell.row.original.code);
              }}
            >
              <RiDeleteBin7Line />
            </button>
          </div>
        );
      },
    },
  ];

  return (
    <section>
      <Buttons
        importPath={importPath}
        exportPath={exportPath}
        importFormatPath={importFormatPath}
      />
      <Table Columns={Columns} Data={data} />
    </section>
  );
};

export default UserManagerPage;
