import React from "react";
import styles from "../styles/styles";
import { IoMdArrowBack } from "react-icons/io";

const BackButton = () => {
    const handleBack = () => {
        window.history.back();
    };

    return (
        <section>
            <div className="flex items-center py-2 lg:px-3">
                <div className="flex-1 flex gap-2">
                    <button onClick={handleBack} className={`${styles.button}`}>
                        <IoMdArrowBack className="mt-0.5" /> Back
                    </button>
                </div>
            </div>
            <hr className="border-gray-300 mt-1" />
        </section>
    );
};

export default BackButton;
