import React, { useState } from "react";
import { RiMenuUnfoldLine, RiMenuFoldLine } from "react-icons/ri";
import { CgProfile } from "react-icons/cg";
import { BiEdit } from "react-icons/bi";
import { MdOutlineLogout } from "react-icons/md";
import { useNavigate } from "react-router-dom";

const Navbar = ({ isShowMenu, setShowMenu }) => {
    const [isShowProfile, setShowProfile] = useState(false);
    const navigate = useNavigate();

    const handleMenu = () => {
        setShowMenu(!isShowMenu);
    };

    const handleLogout = () => {
        localStorage.removeItem("token");
        navigate("/login");
    };

    return (
        <div className="navbar bg-[#fff] rounded-md mb-1">
            <div className="navbar-start flex items-center gap-3">
                <div className="ml-2">
                    {isShowMenu === false ? (
                        <RiMenuUnfoldLine
                            onClick={handleMenu}
                            className="h-5 w-5 cursor-pointer"
                        />
                    ) : (
                        <RiMenuFoldLine
                            onClick={handleMenu}
                            className="h-5 w-5 cursor-pointer"
                        />
                    )}
                </div>

                {/* company name */}
                {/* <h2 className="text-lg lg:text-2xl font-bold mb-1">
                    Company Name
                </h2> */}
            </div>

            {/* navbar end */}
            <div className="flex items-center gap-2 navbar-end mr-2">
                <span className="font-semibold hidden lg:block">
                    Super Admin
                </span>
                <div className="relative">
                    <div
                        onClick={() => setShowProfile(!isShowProfile)}
                        className="w-7 rounded-full cursor-pointer"
                    >
                        <CgProfile className="w-7 h-7" />
                    </div>
                    {isShowProfile && (
                        <div className="bg-[#fff] rounded-md pt-10 p-6 w-56 absolute z-50 right-0 top-8 shadow">
                            <div className="text-center leading-5">
                                <h3 className="font-semibold">Super Admin</h3>
                                <small className="font-medium text-gray-600">
                                    super.admin@example.com
                                </small>
                            </div>
                            <hr className="my-4" />

                            <ul className="space-y-1.5">
                                <li
                                    onClick={() => setShowProfile(false)}
                                    className="flex items-center gap-2 hover:text-blue-500 cursor-pointer"
                                >
                                    <BiEdit /> Change Password
                                </li>
                                <li
                                    onClick={handleLogout}
                                    className="flex items-center gap-2 hover:text-blue-500 cursor-pointer"
                                >
                                    <MdOutlineLogout /> Logout
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default Navbar;
