import React from "react";
import { Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import NotFound from "./components/NotFound";
import Dashboard from "./pages/Dashboard/Dashboard";
import LoginPage from "./pages/Authentication/LoginPage";
import TerritoryPage from "./pages/Dashboard/TerritoryPage";
import BusinessPage from "./pages/Dashboard/BusinessPage";
import DistributorsPage from "./pages/Dashboard/DistributorsPage";
import BeatPage from "./pages/Dashboard/BeatPage";
import EmployeePage from "./pages/Dashboard/EmployeePage";
import EmployeeBeatPage from "./pages/Dashboard/EmployeeBeatPage";
import SRTargetPage from "./pages/Dashboard/SRTargetPage";
import RetailerPage from "./pages/Dashboard/RetailerPage";
import SRBeatPlanPage from "./pages/Dashboard/SRBeatPlanPage";
import ProductPage from "./pages/Dashboard/ProductPage";
import InvoicePage from "./pages/Dashboard/InvoicePage";
import RetailerDuePage from "./pages/Dashboard/RetailerDuePage";
// import AllDataReportPage from "./pages/Dashboard/AllDataReportPage";
// import SROrderDetailsPage from "./pages/Dashboard/SROrderDetailsPage";
// import MapTrackerPage from "./pages/Dashboard/MapTrackerPage";
// import SRExtraInfoPage from "./pages/Dashboard/SRExtraInfoPage";
// import SRAttendanceReportPage from "./pages/Dashboard/SRAttendanceReportPage";
// import HitCountPage from "./pages/Dashboard/HitCountPage";
// import RetailerDueReportPage from "./pages/Dashboard/RetailerDueReportPage";
// import DBDueReportPage from "./pages/Dashboard/DBDueReportPage";
// import DBDashboardReportPage from "./pages/Dashboard/DBDashboardReportPage";
// import PaymentReferencePage from "./pages/Dashboard/PaymentReferencePage";
import UserManagerPage from "./pages/Dashboard/UserManagerPage";
import PermissionPage from "./pages/Dashboard/PermissionPage";
import Layout from "./layouts/Layout";
import CreateUser from "./components/UserManager/CreateUser";
import EditUser from "./components/UserManager/EditUser";
import CreatePermission from "./components/Permission/CreatePermission";
import EditPermission from "./components/Permission/EditPermission";
import EditEmployee from "./components/Employee/EditEmployee";
import CreateTerritory from "./components/Territory/CreateTerritory";
import EditTerritory from "./components/Territory/EditTerritory";
import CreateBusiness from "./components/Business/CreateBusiness";
import EditBusiness from "./components/Business/EditBusiness";
import PurchasingPage from "./pages/Dashboard/PurchasingPage";
import OrderListsPage from "./pages/Dashboard/OrderListsPage";
import OrderReceivePage from "./pages/Dashboard/OrderReceivePage";
import BankPage from "./pages/Dashboard/BankPage";
import CreatePurchasing from "./components/Purchasing/CreatePurchasing";
import CreateBank from "./components/Bank/CreateBank";
import EditBank from "./components/Bank/EditBank";
import CreateDistributor from "./components/Distributor/CreateDistributor";
import EditDistributor from "./components/Distributor/EditDistributor";
import CreateBeat from "./components/Beat/CreateBeat";
import EditBeat from "./components/Beat/EditBeat";
import CreateEmployeeBeat from "./components/EmployeeBeat/CreateEmployeeBeat";
import EditEmployeeBeat from "./components/EmployeeBeat/EditEmployeeBeat";
import CreateSRBeatPlan from "./components/SRBeatPlan/CreateSRBeatPlan";
import EditSRBeatPlan from "./components/SRBeatPlan/EditSRBeatPlan";
import CreateSRTarget from "./components/SRTarget/CreateSRTarget";
import EditSRTarget from "./components/SRTarget/EditSRTarget";
import CreateRetailer from "./components/Retailer/CreateRetailer";
import EditRetailer from "./components/Retailer/EditRetailer";
import CreateProduct from "./components/Product/CreateProduct";
import EditProduct from "./components/Product/EditProduct";
import CreateRetailerDue from "./components/RetailerDue/CreateRetailerDue";
import EditRetailerDue from "./components/RetailerDue/EditRetailerDue";
import BrandPage from "./pages/Dashboard/BrandPage";
import ChannelTypePage from "./pages/Dashboard/ChannelTypePage";
import CreateBrand from "./components/Brand/CreateBrand";
import CreateChannelType from "./components/ChannelType/CreateChannelType";
import CategoryPage from "./pages/Dashboard/CategoryPage";
import CreateCategory from "./components/Category/CreateCategory";
import UserRequestPage from "./pages/Dashboard/UserRequestPage";
import DepartmentPage from "./pages/Dashboard/DepartmentPage";
import DesignationPage from "./pages/Dashboard/DesignationPage";
import CreateDepartment from "./components/Department/CreateDepartment";
import CreateDesignation from "./components/Designation/CreateDesignation";
import CreateEmployee from "./components/Employee/CreateEmployee";
import TerritoryWiseBeatAssignPage from "./pages/Dashboard/TerritoryWiseBeatAssignPage";
import CreateTerritoryWiseBeat from "./components/TerritoryWiseBeat/CreateTerritoryWiseBeat";
import EditTerritoryWiseBeat from "./components/TerritoryWiseBeat/EditTerritoryWiseBeat";
import ProductWisePricePage from "./pages/Dashboard/ProductWisePricePage";
import CreateProductWisePrice from "./components/ProductWisePrice/CreateProductWisePrice";
import EditProductWisePrice from "./components/ProductWisePrice/EditProductWisePrice";
import ProductWisePriceHistoryPage from "./pages/Dashboard/ProductWisePriceHistoryPage";
import DistributorWiseTerritoryAssignPage from "./pages/Dashboard/DistributorWiseTerritoryAssignPage";
import CreateDistributorWiseTerritory from "./components/DistributorWiseTerritory/CreateDistributorWiseTerritory";
import EditDistributorWiseTerritory from "./components/DistributorWiseTerritory/EditDistributorWiseTerritory";
import EditBrand from "./components/Brand/EditBrand";
import EditChannelType from "./components/ChannelType/EditChannelType";
import EditCategory from "./components/Category/EditCategory";
import MapsPage from "./pages/Dashboard/MapsPage";
import MapsWithoutFilterPage from "./pages/Dashboard/MapsWithoutFilterPage";

function App() {
  return (
    <div className="min-h-screen bg-[#ececec] text-[#373737]">
      <Routes>
        {/* authentication */}
        <Route path="/login" element={<LoginPage />}></Route>

        {/* dashboard */}
        <Route path="/" element={<Layout />}>
          <Route path="/dashboard" element={<Dashboard />} />

          {/* hr */}
          <Route path="/employees" element={<EmployeePage />} />
          <Route path="/employees/create" element={<CreateEmployee />} />
          <Route path="/employees/edit/:code" element={<EditEmployee />} />
          <Route path="/employee-assignments" element={<EmployeeBeatPage />} />
          <Route
            path="/employee-assignments/create"
            element={<CreateEmployeeBeat />}
          />
          <Route
            path="/employee-assignments/edit/:code"
            element={<EditEmployeeBeat />}
          />
          <Route path="/sr-beat-plans" element={<SRBeatPlanPage />} />
          <Route path="/sr-beat-plans/create" element={<CreateSRBeatPlan />} />
          <Route
            path="/sr-beat-plans/edit/:code"
            element={<EditSRBeatPlan />}
          />
          <Route path="/sr-targets" element={<SRTargetPage />} />
          <Route path="/sr-targets/create" element={<CreateSRTarget />} />
          <Route path="/sr-targets/edit/:code" element={<EditSRTarget />} />
          <Route path="/retailers" element={<RetailerPage />} />
          <Route path="/retailers/create" element={<CreateRetailer />} />
          <Route path="/retailers/edit/:code" element={<EditRetailer />} />
          <Route path="/products" element={<ProductPage />} />
          <Route path="/products/create" element={<CreateProduct />} />
          <Route path="/products/edit/:code" element={<EditProduct />} />
          <Route path="/product-prices" element={<ProductWisePricePage />} />
          <Route
            path="/product-prices/create"
            element={<CreateProductWisePrice />}
          />
          <Route
            path="/product-prices/edit/:code"
            element={<EditProductWisePrice />}
          />
          <Route path="/invoices" element={<InvoicePage />} />
          <Route path="/retailer-dues" element={<RetailerDuePage />} />
          <Route path="/retailer-dues/create" element={<CreateRetailerDue />} />
          <Route
            path="/retailer-dues/edit/:code"
            element={<EditRetailerDue />}
          />
          <Route path="/purchasing" element={<PurchasingPage />} />
          <Route path="/purchasing/create" element={<CreatePurchasing />} />
          <Route path="/order-lists" element={<OrderListsPage />} />
          <Route path="/order-receives" element={<OrderReceivePage />} />

          {/* reports */}
          <Route
            path="/product-price-history"
            element={<ProductWisePriceHistoryPage />}
          />
          <Route path="/maps" element={<MapsPage />} />
          <Route
            path="/maps-without-filter"
            element={<MapsWithoutFilterPage />}
          />
          {/* <Route path="/all-data" element={<AllDataReportPage />} />
          <Route
            path="/payment-references"
            element={<PaymentReferencePage />}
          />
          <Route path="/sr-order-details" element={<SROrderDetailsPage />} />
          <Route path="/map-tracker" element={<MapTrackerPage />} />
          <Route path="/sr-extra-infos" element={<SRExtraInfoPage />} />
          <Route path="/sr-attendance" element={<SRAttendanceReportPage />} />
          <Route path="/hit-counts" element={<HitCountPage />} />
          <Route
            path="/retailer-dues-reports"
            element={<RetailerDueReportPage />}
          />
          <Route path="/db-dues" element={<DBDueReportPage />} />
          <Route path="/db-dashboard" element={<DBDashboardReportPage />} /> */}

          {/* setup */}
          <Route path="/principals" element={<BusinessPage />} />
          <Route path="/principals/create" element={<CreateBusiness />} />
          <Route path="/principals/edit/:code" element={<EditBusiness />} />
          <Route path="/territories" element={<TerritoryPage />} />
          <Route path="/territories/create" element={<CreateTerritory />} />
          <Route path="/territories/edit/:code" element={<EditTerritory />} />
          <Route path="/beats" element={<BeatPage />} />
          <Route path="/beats/create" element={<CreateBeat />} />
          <Route path="/beats/edit/:code" element={<EditBeat />} />
          <Route
            path="/territory-beat-assignments"
            element={<TerritoryWiseBeatAssignPage />}
          />
          <Route
            path="/territory-beat-assignments/create"
            element={<CreateTerritoryWiseBeat />}
          />
          <Route
            path="/territory-beat-assignments/edit/:code"
            element={<EditTerritoryWiseBeat />}
          />
          <Route path="/distributors" element={<DistributorsPage />} />
          <Route path="/distributors/create" element={<CreateDistributor />} />
          <Route
            path="/distributors/edit/:code"
            element={<EditDistributor />}
          />
          <Route
            path="/distributor-territory-assignments"
            element={<DistributorWiseTerritoryAssignPage />}
          />
          <Route
            path="/distributor-territory-assignments/create"
            element={<CreateDistributorWiseTerritory />}
          />
          <Route
            path="/distributor-territory-assignments/edit/:code"
            element={<EditDistributorWiseTerritory />}
          />

          {/* settings */}
          <Route path="/user-managers" element={<UserManagerPage />} />
          <Route path="/user-managers/create" element={<CreateUser />} />
          <Route path="/user-managers/edit/:code" element={<EditUser />} />
          <Route path="/user-requests" element={<UserRequestPage />} />
          <Route path="/permissions" element={<PermissionPage />} />
          <Route path="/permissions/create" element={<CreatePermission />} />
          <Route path="/permissions/edit/:code" element={<EditPermission />} />

          {/* house keeping */}
          <Route path="/brands" element={<BrandPage />} />
          <Route path="/brands/create" element={<CreateBrand />} />
          <Route path="/brands/edit/:code" element={<EditBrand />} />
          <Route path="/channel-types" element={<ChannelTypePage />} />
          <Route path="/channel-types/create" element={<CreateChannelType />} />
          <Route
            path="/channel-types/edit/:code"
            element={<EditChannelType />}
          />
          <Route path="/categories" element={<CategoryPage />} />
          <Route path="/categories/create" element={<CreateCategory />} />
          <Route path="/categories/edit/:code" element={<EditCategory />} />
          <Route path="/bank-infos" element={<BankPage />} />
          <Route path="/bank-infos/create" element={<CreateBank />} />
          <Route path="/bank-infos/edit/:code" element={<EditBank />} />
          <Route path="/departments" element={<DepartmentPage />} />
          <Route path="/departments/create" element={<CreateDepartment />} />
          <Route
            path="/departments/edit/:code"
            element={<CreateDepartment />}
          />
          <Route path="/designations" element={<DesignationPage />} />
          <Route path="/designations/create" element={<CreateDesignation />} />
          <Route
            path="/designations/edit/:code"
            element={<CreateDesignation />}
          />
        </Route>

        {/* not found */}
        <Route path="/*" element={<NotFound />}></Route>
      </Routes>
      <Toaster position="top-center" />
    </div>
  );
}

export default App;
