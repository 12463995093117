import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";

const initialState = {
  period: 0,
  distributorCode: "",
  srCode: "",
  principal: "",
  brandCode: "",
  amount: 0,
};

const CreateSRTarget = () => {
  const [formData, setFormData] = useState(initialState);
  const [distributorList, setDistributorList] = useState([]);
  const [srList, setSRList] = useState([]);
  const [businessList, setBusinessList] = useState([]);
  const [brandList, setBrandList] = useState([]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    formData.period = parseInt(formData.period);
    formData.amount = parseInt(formData.amount);
    postData("sr-targets/create-sr-target", { srTarget: formData });
    setFormData(initialState);
  };

  useEffect(() => {
    getData("distributors", setDistributorList);
    getData("employees", setSRList);
    getData("principals", setBusinessList);
    getData("brands", setBrandList);
  }, []);

  let brands = [];
  if (businessList) {
    brands = brandList.filter(
      (brand) => brand.businessCode === formData.principal
    );
  }

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create SR Target
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Period<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                placeholder="Enter Period"
                name="period"
                className={`${styles.input}`}
                value={formData?.period || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    period: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full"></div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Employee Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="employees"
                type="text"
                required
                placeholder="Enter EmployeeCode"
                name="srCode"
                className={`${styles.input}`}
                value={formData?.srCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    srCode: e.target.value,
                  });
                }}
              />
              <datalist id="employees">
                {srList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.srName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Distributor Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="distributors"
                type="text"
                required
                placeholder="Enter DistributorCode"
                name="distributorCode"
                className={`${styles.input}`}
                value={formData?.distributorCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    distributorCode: e.target.value,
                  });
                }}
              />
              <datalist id="distributors">
                {distributorList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.distributorName}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Principal<span className="text-red-500">*</span>
              </label>
              <input
                list="principals"
                type="text"
                required
                placeholder="Enter Principle"
                name="principal"
                className={`${styles.input}`}
                value={formData?.principal || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    principal: e.target.value,
                  });
                }}
              />
              <datalist id="principals">
                {businessList.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.principalName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Brand<span className="text-red-500">*</span>
              </label>
              <input
                list="brands"
                type="text"
                required
                placeholder="Enter Brand"
                name="brandCode"
                className={`${styles.input}`}
                value={formData?.brandCode || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    brandCode: e.target.value,
                  });
                }}
              />
              <datalist id="brands">
                {brands.map((item, index) => (
                  <option key={index} value={item.brandName}>
                    {item.code + " | " + item.brandName}
                  </option>
                ))}
              </datalist>
            </div>
          </div>
          <div className="w-full flex items-center gap-4">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Amount<span className="text-red-500">*</span>
              </label>
              <input
                type="number"
                required
                placeholder="Enter Amount"
                name="amount"
                className={`${styles.input}`}
                value={formData?.amount || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    amount: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full"></div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save SR Target
          </button>
        </form>
      </div>
    </section>
  );
};

export default CreateSRTarget;
