import React from "react";
import { BsBox, BsShield } from "react-icons/bs";
import { PiCodesandboxLogo } from "react-icons/pi";
import { TfiViewListAlt } from "react-icons/tfi";
import { HiOutlineViewGridAdd } from "react-icons/hi";
import { MdOutlineAddToPhotos } from "react-icons/md";
import { FaRegSquareCheck } from "react-icons/fa6";
import { MdOutlineManageAccounts } from "react-icons/md";

export const DashboardMenu = [
  {
    title: "Main",
    children: [
      {
        text: "Dashboard",
        route: "/dashboard",
        icon: <BsBox />,
      },
    ],
  },
  {
    title: "Basic",
    children: [
      {
        text: "SR Beat Plan",
        route: "/sr-beat-plans",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "SR Target",
        route: "/sr-targets",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Retailer",
        route: "/retailers",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Invoice",
        route: "/invoices",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Retailer Due",
        route: "/retailer-dues",
        icon: <PiCodesandboxLogo />,
      },
    ],
  },
  {
    title: "Purchasing",
    children: [
      {
        text: "Purchasing",
        route: "/purchasing",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Order Lists",
        route: "/order-lists",
        icon: <PiCodesandboxLogo />,
      },
    ],
  },
  {
    title: "Inventory",
    children: [
      {
        text: "Order Receive",
        route: "/order-receives",
        icon: <PiCodesandboxLogo />,
      },
    ],
  },
  {
    title: "HR",
    children: [
      {
        text: "Employee In",
        route: "/employees",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Employee Assignment",
        route: "/employee-assignments",
        icon: <PiCodesandboxLogo />,
      },
    ],
  },
  {
    title: "Reports",
    children: [
      {
        text: "Product Wise Price History",
        route: "/product-price-history",
        icon: <TfiViewListAlt />,
      },
      {
        text: "Maps",
        route: "/maps",
        icon: <TfiViewListAlt />,
      },
      // {
      //   text: "Maps Without Filter",
      //   route: "/maps-without-filter",
      //   icon: <TfiViewListAlt />,
      // },
      //     {
      //         text: "All Data Report",
      //         route: "/all-data",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "Payment References",
      //         route: "/payment-references",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "SR Order Details",
      //         route: "/sr-order-details",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "Map Tracker",
      //         route: "/map-tracker",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "SR Extra Info",
      //         route: "/sr-extra-infos",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "SR Attendance Report",
      //         route: "/sr-attendance",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "Hit Count",
      //         route: "/hit-counts",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "Retailer Due Report",
      //         route: "/retailer-dues-reports",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "DB Due Report",
      //         route: "/db-dues",
      //         icon: <TfiViewListAlt />,
      //     },
      //     {
      //         text: "DB Dashboard Report",
      //         route: "/db-dashboard",
      //         icon: <TfiViewListAlt />,
      //     },
    ],
  },
  {
    title: "Setup",
    children: [
      {
        text: "Principal",
        route: "/principals",
        icon: <HiOutlineViewGridAdd size={15} />,
      },
      {
        text: "Territory",
        route: "/territories",
        icon: <HiOutlineViewGridAdd size={15} />,
      },
      {
        text: "Beat / Route",
        route: "/beats",
        icon: <HiOutlineViewGridAdd size={15} />,
      },
      // {
      //   text: "Territory wise Beat Assign",
      //   route: "/territory-beat-assignments",
      //   icon: <HiOutlineViewGridAdd size={15} />,
      // },
      {
        text: "Distributors",
        route: "/distributors",
        icon: <HiOutlineViewGridAdd size={15} />,
      },
      // {
      //   text: "Distributor wise Territory Assign",
      //   route: "/distributor-territory-assignments",
      //   icon: <HiOutlineViewGridAdd size={15} />,
      // },
    ],
  },
  {
    title: "Settings",
    children: [
      {
        text: "User Manager",
        route: "/user-managers",
        icon: <MdOutlineManageAccounts size={15} />,
      },
      {
        text: "User Request",
        route: "/user-requests",
        icon: <FaRegSquareCheck />,
      },
      {
        text: "Permissions",
        route: "/permissions",
        icon: <BsShield />,
      },
    ],
  },
  {
    title: "House Keeping",
    children: [
      {
        text: "Products",
        route: "/products",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Product wise Price",
        route: "/product-prices",
        icon: <PiCodesandboxLogo />,
      },
      {
        text: "Brands",
        route: "/brands",
        icon: <MdOutlineAddToPhotos />,
      },
      {
        text: "Channel Types",
        route: "/channel-types",
        icon: <MdOutlineAddToPhotos />,
      },
      {
        text: "Categories",
        route: "/categories",
        icon: <MdOutlineAddToPhotos />,
      },
      // {
      //   text: "Bank Info",
      //   route: "/bank-infos",
      //   icon: <MdOutlineAddToPhotos />,
      // },
      {
        text: "Departments",
        route: "/departments",
        icon: <MdOutlineAddToPhotos />,
      },
      {
        text: "Designations",
        route: "/designations",
        icon: <MdOutlineAddToPhotos />,
      },
    ],
  },
];

export const color = {
  view: "#0d9e47",
  edit: "#24b1b7",
  delete: "#f44336",
};

export const channelTypes = {
  CT1: "General Store",
  CT2: "Cosmetics Store",
  CT3: "Chemist/Pharmacy",
  CT4: "General Merchant",
  CT5: "Grocery Store",
  CT6: "Hardware Store",
  CT7: "Institution",
  CT8: "Others",
  CT9: "Saloon",
  CT10: "Sub D",
  CT11: "Shanghar",
  CT12: "Superstore",
  CT13: "Stationary",
  CT14: "Tong/restaurant",
  CT15: "Tong",
  CT16: "Tobacco Plus",
  CT17: "Wholesale",
  CT18: "Confectionary",
};
