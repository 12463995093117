import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
import { getData, postData } from "../../api/Fetch";
import BackButton from "../BackButton";
import { FaSave } from "react-icons/fa";
import { Table } from "../Table";

const initialState = {
  businessCode: "",
  brand: "",
  category: "",
  productName: "",
  packSizeWT: "",
  packSize: "",
  status: "active",
};

const CreateProduct = () => {
  const [formData, setFormData] = useState(initialState);
  const [businessList, setBusinessList] = useState([]);
  const [principal, setPrincipal] = useState("");
  const [brandList, setBrandList] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [data, setData] = useState([]);
  const [reload, setReload] = useState(false);

  useEffect(() => {
    getData("products", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    postData("products/create-product", { product: formData });
    setFormData(initialState);
    setPrincipal("");

    setTimeout(() => {
      setReload(!reload);
    }, [1500]);
  };

  useEffect(() => {
    getData("principals", setBusinessList);
    getData("brands", setBrandList);
    getData("categories", setCategoryList);
  }, []);

  useEffect(() => {
    const newPrincipal = businessList.find(
      (principal) => principal.code === formData.businessCode
    );

    if (newPrincipal) {
      setPrincipal(newPrincipal.principalName);
    }
  }, [businessList, formData]);

  return (
    <section>
      <BackButton />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create Product
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex gap-4 w-full items-center">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Principal Code
                <span className="text-red-500">*</span>
              </label>
              <input
                list="businesses"
                type="text"
                required
                placeholder="Enter PrincipalCode"
                name="businessCode"
                className={`${styles.input}`}
                value={principal || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    businessCode: e.target.value,
                  });
                  setPrincipal(e.target.value);
                }}
              />
              <datalist id="businesses">
                {businessList?.map((item, index) => (
                  <option key={index} value={item.code}>
                    {item.code + " | " + item.principalName}
                  </option>
                ))}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Brand<span className="text-red-500">*</span>
              </label>
              <input
                list="brands"
                type="text"
                required
                placeholder="Enter Brand"
                name="brandCode"
                className={`${styles.input}`}
                value={formData?.brand || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    brand: e.target.value,
                  });
                }}
              />
              <datalist id="brands">
                {brandList.map((item, index) => {
                  return (
                    item.principalCode === formData.businessCode && (
                      <option key={index} value={item.brandName}>
                        {`${item.code} | ${item.brandName}`}
                      </option>
                    )
                  );
                })}
              </datalist>
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Category<span className="text-red-500">*</span>
              </label>
              <input
                list="categories"
                type="text"
                required
                placeholder="Enter Category"
                name="category"
                className={`${styles.input}`}
                value={formData?.category || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    category: e.target.value,
                  });
                }}
              />
              <datalist id="categories">
                {categoryList.map((item, index) => {
                  return (
                    item.brandName === formData.brand && (
                      <option key={index} value={item.categoryName}>
                        {`${item.code} | ${item.categoryName}`}
                      </option>
                    )
                  );
                })}
              </datalist>
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Product Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                required
                placeholder="Enter ProductName"
                name="productName"
                className={`${styles.input}`}
                value={formData?.productName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    productName: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Pack Size WT</label>
              <input
                type="text"
                placeholder="Enter PackSizeWT"
                name="packSizeWT"
                className={`${styles.input}`}
                value={formData?.packSizeWT || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    packSizeWT: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">Pack Size</label>
              <input
                type="text"
                placeholder="Enter PackSize"
                name="packSize"
                className={`${styles.input}`}
                value={formData?.packSize || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    packSize: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                Status<span className="text-red-500">*</span>
              </label>
              <select
                placeholder="Status"
                name="status"
                className={`${styles.select} !w-full ${
                  formData.status === "" && "text-[#979aa1]"
                }`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="w-full flex flex-col">
              <label htmlFor="file" className="text-sm font-medium mb-1">
                Attach File
              </label>
              <input
                type="file"
                name="file"
                id="file"
                className="dark:bg-white w-full input input-xs px-1.5 py-1.5 input-bordered focus:outline-none focus:border-[#24b1b7] focus:border-[1px] rounded-md h-9 text-sm"
              />
            </div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Product
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <ProductList data={data} />
      </div>
    </section>
  );
};

export default CreateProduct;

const ProductList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Principal",
      accessor: "businessName",
    },
    {
      Header: "Brand",
      accessor: "brand",
    },
    {
      Header: "Category",
      accessor: "category",
    },
    {
      Header: "Product Code",
      accessor: "code",
    },
    {
      Header: "Product Name",
      accessor: "productName",
    },
    {
      Header: "Pack Size",
      accessor: "packSize",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        return cell.value === "active" ? (
          <span className="bg-green-500 text-white px-2 py-0.5 rounded-full font-semibold text-xs">
            Active
          </span>
        ) : (
          <span className="bg-red-500 text-white px-2 py-0.5 rounded-full font-semibold text-xs">
            Inactive
          </span>
        );
      },
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
