import { MarkerClusterer } from "@googlemaps/markerclusterer";
import { InfoWindow, useMap } from "@vis.gl/react-google-maps";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import MyAdvancedMarker from "./MyAdvancedMarker";
import MyAdvMarker from "./MyAdvMarker";
import { channelTypes } from "../../static/data";

const ClusteredMarkers = ({ orders, data }) => {
  const [infoWindowOpen, setInfoWindowOpen] = useState(null);
  const [markers, setMarkers] = useState([]);

  const map = useMap();
  const clusterer = useMemo(() => {
    if (!map) return null;

    return new MarkerClusterer({ map });
  }, [map]);

  useEffect(() => {
    if (!clusterer) return;

    clusterer.clearMarkers();
    clusterer.addMarkers(Object.values(markers));
  }, [clusterer, markers]);

  const setMarkerRef = useCallback((marker, key) => {
    setMarkers((markers) => {
      if ((marker && markers[key]) || (!marker && !markers[key]))
        return markers;

      if (marker) {
        return { ...markers, [key]: marker };
      } else {
        const { [key]: _, ...newMarkers } = markers;

        return newMarkers;
      }
    });
  }, []);

  return (
    <>
      {orders &&
        orders.map((d, index) => (
          <MyAdvancedMarker
            key={index}
            item={d}
            setMarkerRef={setMarkerRef}
            setInfoWindowOpen={setInfoWindowOpen}
          />
        ))}
      {data &&
        data.map((d, index) => (
          <MyAdvMarker
            key={index}
            item={d}
            setMarkerRef={setMarkerRef}
            setInfoWindowOpen={setInfoWindowOpen}
          />
        ))}

      {infoWindowOpen && orders.length > 0 && (
        <InfoWindow
          anchor={markers[infoWindowOpen.code]}
          onCloseClick={() => setInfoWindowOpen(null)}
        >
          <div className="flex flex-col gap-0.5">
            <p className="text-xs font-medium">
              Order ID: {infoWindowOpen?.code}
            </p>
            <p className="text-xs font-medium">
              SR Code: {infoWindowOpen?.srCode}
            </p>
            <p className="text-xs font-medium">
              Customer Code: {infoWindowOpen?.customerCode}
            </p>
            <p className="text-xs font-medium">
              Order Date: {infoWindowOpen?.orderedAt}
            </p>
            <p className="text-xs font-medium">
              Status: {infoWindowOpen?.status}
            </p>
          </div>
        </InfoWindow>
      )}

      {infoWindowOpen && data.length > 0 && (
        <InfoWindow
          anchor={markers[infoWindowOpen.code]}
          onCloseClick={() => setInfoWindowOpen(null)}
        >
          <div className="flex flex-col gap-0.5">
            <p className="text-xs font-medium">
              Customer Code: {infoWindowOpen?.code}
            </p>
            <p className="text-xs font-medium">
              Retailer Name: {infoWindowOpen?.retailerName}
            </p>
            <p className="text-xs font-medium">
              Channel Type: {channelTypes[infoWindowOpen?.channelType]}
            </p>
          </div>
        </InfoWindow>
      )}
    </>
  );
};

export default ClusteredMarkers;
