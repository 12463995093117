import React, { useEffect, useState } from "react";
import styles from "../../styles/styles";
// import { FiFilter } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
import { getData } from "../../api/Fetch";

const FilterPurchasing = ({ data, setData }) => {
  //   const [formData, setFormData] = useState({
  //     primary: "",
  //     brand: "",
  //     category: "",
  //     search: "",
  //   });
  const [business, setBusiness] = useState([]);
  const [brand, setBrand] = useState([]);
  const [categories, setCategories] = useState([]);

  //   const handleSubmit = (e) => {
  //     e.preventDefault();
  //     setData(formData);
  //   };

  useEffect(() => {
    getData("principals", setBusiness);
    getData("brands", setBrand);
    getData("categories", setCategories);
    setData({
      primary: "",
      brand: "",
      category: "",
      search: "",
    });
  }, [setBusiness, setBrand, setCategories, setData]);

  return (
    <div className="mt-2.5 lg:mx-3">
      <form className="flex gap-3 items-end">
        <div className="flex-auto flex gap-3">
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium mb-1">
              <span>Principal</span>
            </label>
            <select
              name="primary"
              className={`${styles.select} !w-full ${
                data.primary === "" && "text-[#979aa1]"
              }`}
              onChange={(e) => {
                setData({
                  ...data,
                  primary: e.target.value,
                });
              }}
            >
              <option value="">Select a principal</option>
              {business.map((item) => (
                <option key={item._id} value={item.code}>
                  {item.principalName}
                </option>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium mb-1">
              <span>Brand</span>
            </label>
            <select
              name="brand"
              className={`${styles.select} !w-full ${
                data.brand === "" && "text-[#979aa1]"
              }`}
              onChange={(e) => {
                setData({
                  ...data,
                  brand: e.target.value,
                });
              }}
            >
              <option value="">Select a brand</option>
              {brand.map((item) => (
                <>
                  {item.principalCode === data.primary && (
                    <option key={item._id} value={item.brandName}>
                      {item.brandName}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium mb-1">
              <span>Category</span>
            </label>
            <select
              name="category"
              className={`${styles.select} !w-full ${
                data.category === "" && "text-[#979aa1]"
              }`}
              onChange={(e) => {
                setData({
                  ...data,
                  category: e.target.value,
                });
              }}
            >
              <option value="">Select a category</option>
              {categories.map((item) => (
                <>
                  {item.principalCode === data.primary && (
                    <option key={item._id} value={item.categoryName}>
                      {item.categoryName}
                    </option>
                  )}
                </>
              ))}
            </select>
          </div>
          <div className="w-full flex flex-col">
            <label className="text-sm font-medium mb-1">
              <span>Search</span>
            </label>
            <div className="relative">
              <input
                type="text"
                placeholder="Search"
                value={data.search || ""}
                onChange={(e) => {
                  setData({
                    ...data,
                    search: e.target.value,
                  });
                }}
                className={`${styles.input}`}
              />
              <FaSearch className="text-gray-400 absolute right-3 top-2.5" />
            </div>
          </div>
        </div>
        {/* <button type="submit" className={`${styles.button} h-9`}>
          <FiFilter /> Filter
        </button> */}
      </form>
    </div>
  );
};

export default FilterPurchasing;
