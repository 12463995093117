import React, { useState } from "react";
import styles from "../styles/styles";
import { FiFilter } from "react-icons/fi";
import { FaSearch } from "react-icons/fa";
// import { MdOutlineDateRange } from "react-icons/md";

const FilterOrders = ({ setData }) => {
    const [formData, setFormData] = useState({
        search: "",
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        // console.log(formData);
    };

    return (
        <div className="mt-2.5 lg:mx-3">
            <form
                onSubmit={handleSubmit}
                className="flex gap-3 items-end max-w-xl"
            >
                <div className="flex-auto flex items-center gap-3">
                    <div className="w-full flex flex-col">
                        <label className="text-sm font-medium mb-1">
                            <span>Search</span>
                        </label>
                        <div className="relative">
                            <input
                                type="text"
                                placeholder="Search"
                                value={formData.search || ""}
                                onChange={(e) => {
                                    setFormData({
                                        ...formData,
                                        search: e.target.value,
                                    });
                                }}
                                className={`${styles.input}`}
                            />
                            <FaSearch className="text-gray-400 absolute right-3 top-2.5" />
                        </div>
                    </div>
                </div>
                <button type="submit" className={`${styles.button} h-[35px]`}>
                    <FiFilter /> Filter
                </button>
            </form>
        </div>
    );
};

export default FilterOrders;
