import React from "react";
import { Link, useLocation } from "react-router-dom";

const DashboardMenuItem = ({ text, route, icon }) => {
    const location = useLocation();

    return (
        <li
            className={`${
                location.pathname.split("/").includes(route.slice(1))
                    ? "border-l-2 border-[#fff]"
                    : "border-l-2 border-transparent"
            } py-0.5`}
        >
            <Link
                to={`${route}`}
                className="flex items-center gap-2.5 ml-1.5 text-sm font-semibold group transition-all duration-300 ease-in-out"
            >
                {icon}{" "}
                <span className="group-hover:translate-x-0.5 duration-300">
                    {text}
                </span>
            </Link>
        </li>
    );
};

export default DashboardMenuItem;
