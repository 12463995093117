import React, { useEffect, useState } from "react";
import BackButtons from "../BackButton";
import styles from "../../styles/styles";
import { FaSave } from "react-icons/fa";
import { getData, postData } from "../../api/Fetch";
import { Table } from "../Table";

const initialState = {
  code: "",
  territoryName: "",
  territoryDescription: "",
  status: "active",
};

const CreateTerritory = () => {
  const [formData, setFormData] = useState(initialState);
  const [reload, setReload] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    getData("territories", setData);
  }, [reload, setReload]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    postData("territories/create-territory", { territory: formData });
    setFormData(initialState);

    // wait 1sec
    setTimeout(() => {
      setReload(!reload);
    }, [1000]);
  };

  return (
    <section>
      <BackButtons />

      <div className="bg-[#fff] p-4 rounded-lg mt-2.5 mx-3">
        <p className="font-semibold uppercase text-[15px] mb-2">
          Create Territory
        </p>

        <form onSubmit={handleSubmit} className="space-y-2.5">
          <div className="flex flex-col gap-2.5 items-center w-full">
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory Name
                <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                placeholder="Enter TerritoryName"
                name="territoryName"
                className={`${styles.input}`}
                value={formData?.territoryName || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territoryName: e.target.value,
                  });
                }}
              />
            </div>
            <div className="w-full">
              <label className="text-sm font-medium mb-1">
                Territory Description<span className="text-red-500">*</span>
              </label>
              <textarea
                required
                placeholder="Enter TerritoryDescription"
                name="territoryDescription"
                className={`${styles.textarea}`}
                value={formData?.territoryDescription || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    territoryDescription: e.target.value,
                  });
                }}
              />
            </div>
          </div>
          <div className="flex gap-4 items-center w-full">
            <div className="w-full flex flex-col">
              <label className="text-sm font-medium mb-1">
                <span>Status</span>
              </label>
              <select
                placeholder="Status"
                name="status"
                className={`${styles.select} !w-full ${
                  formData.status === "" && "text-[#979aa1]"
                }`}
                value={formData?.status || ""}
                onChange={(e) => {
                  setFormData({
                    ...formData,
                    status: e.target.value,
                  });
                }}
              >
                <option value="">Select Status</option>
                <option value="active">Active</option>
                <option value="inactive">Inactive</option>
              </select>
            </div>
            <div className="w-full"></div>
          </div>

          <button className={`${styles.button} !mt-4`} type="submit">
            <FaSave className="mb-0.5" /> Save Territory
          </button>
        </form>
      </div>

      <div className="bg-[#fff] p-2 rounded-lg m-3">
        <TerritoryList data={data} />
      </div>
    </section>
  );
};

export default CreateTerritory;

const TerritoryList = ({ data }) => {
  const Columns = [
    {
      Header: "#",
      Cell: ({ cell }) => {
        return cell.row.index + 1;
      },
    },
    {
      Header: "Territory Code",
      accessor: "code",
    },
    {
      Header: "Territory Name",
      accessor: "territoryName",
    },
    {
      Header: "Territory Description",
      accessor: "territoryDescription",
    },
    {
      Header: "Status",
      accessor: "status",
      Cell: ({ cell }) => {
        return cell.value === "active" ? (
          <span className="bg-green-500 text-white px-2 py-0.5 rounded-full font-semibold text-xs">
            Active
          </span>
        ) : cell.value === "inactive" ? (
          <span className="bg-red-500 text-white px-2 py-0.5 rounded-full font-semibold text-xs">
            Inactive
          </span>
        ) : (
          ""
        );
      },
    },
  ];

  return <Table Columns={Columns} Data={data} />;
};
