import React, { useState } from "react";
import styles from "../styles/styles";
import toast from "react-hot-toast";
import { ImCancelCircle } from "react-icons/im";
import { FaUpload } from "react-icons/fa";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const FileImport = ({ path, setImporting }) => {
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("Choose File");

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);

    fetch(`${BASE_URL}/${path}`, {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((data) => {
        toast.success(`Successfully imported ${data.count} records`);
      })
      .catch((error) => {
        toast.error("Something went wrong! Try again.");
      });

    setImporting(false);
  };

  return (
    <section>
      <div className="modal modal-open">
        <div className="modal-box bg-gray-100">
          <div className="flex items-center justify-center w-full">
            <label
              for="dropzone-file"
              className="flex flex-col items-center justify-center w-full h-56 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-200 hover:bg-gray-300"
            >
              <div className="flex flex-col items-center justify-center pt-5 pb-6">
                <svg
                  className="w-8 h-8 mb-4 text-gray-600"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 20 16"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M13 13h3a3 3 0 0 0 0-6h-.025A5.56 5.56 0 0 0 16 6.5 5.5 5.5 0 0 0 5.207 5.021C5.137 5.017 5.071 5 5 5a4 4 0 0 0 0 8h2.167M10 15V6m0 0L8 8m2-2 2 2"
                  />
                </svg>
                <p className="mb-2 text-sm text-gray-500">
                  <span className="font-semibold">Click to upload</span>
                </p>
                <p className="text-xs text-gray-500">{fileName}</p>
              </div>
              <input
                id="dropzone-file"
                type="file"
                className="hidden"
                accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                onChange={(e) => {
                  setFile(e.target.files[0]);
                  setFileName(e.target.files[0].name);
                }}
              />
            </label>
          </div>

          <div className="flex items-center mt-4 gap-4 w-full">
            <button
              className={`${styles.button} px-6 !bg-[#ef4444] flex-1`}
              onClick={() => setImporting(false)}
            >
              <ImCancelCircle /> Cancel
            </button>
            <button
              className={`${styles.button} px-6 !bg-[#0d9e47] flex-1`}
              onClick={handleSubmit}
            >
              <FaUpload /> Upload
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FileImport;
