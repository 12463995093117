import React, { useCallback } from "react";
import { AdvancedMarker } from "@vis.gl/react-google-maps";

const MyAdvancedMarker = ({ item, setMarkerRef, setInfoWindowOpen }) => {
  const ref = useCallback(
    (marker) => setMarkerRef(marker, item.code),
    [setMarkerRef, item.code]
  );

  return (
    <>
      <AdvancedMarker
        ref={ref}
        onClick={() => setInfoWindowOpen(item)}
        position={{
          lat: parseFloat(item.location.lat),
          lng: parseFloat(item.location.long),
        }}
      >
        <div className="w-4 h-4 rounded-full bg-blue-600 border-[3px] border-blue-300"></div>
      </AdvancedMarker>

      {/* {infoWindowOpen && (
        <InfoWindow
          anchor={10}
          position={{
            lat: parseFloat(item.location.lat),
            lng: parseFloat(item.location.long),
          }}
          onCloseClick={() => setInfoWindowOpen(false)}
        >
          <div className="flex flex-col gap-0.5">
            <small>Order ID: {item?.code}</small>
            <small>SR Code: {item?.srCode}</small>
            <small>Customer Code: {item?.customerCode}</small>
            <small>Order Date: {item?.orderedAt}</small>
            <small>Status: {item?.status}</small>
          </div>
        </InfoWindow>
      )} */}
    </>
  );
};

export default MyAdvancedMarker;
